import React, { useState, useRef, useEffect } from 'react';
import CharacterCount from './CharacterCount';
import Modal from './Modal'; // Import the updated Modal component
import './HorizontalHeader.css';
import { ClockIcon, ImportIcon, ViewIcon, OptionIcon } from './SVGLibrary.js';
import { useNavigate } from 'react-router-dom';

const HorizontalHeader = ({ characterCount, isSaving }) => {
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false); // State for the modal
  const optionDropdownRef = useRef(null);
  const navigate = useNavigate();
  const [isWordCountVisible, setIsWordCountVisible] = useState(() => 
    localStorage.getItem('showWordCount') !== 'false'
  );

  const toggleOptionDropdown = () => {
    setIsOptionDropdownOpen(!isOptionDropdownOpen);
  };

  const openSettingsModal = () => {
    setIsSettingsModalOpen(true);
    setIsOptionDropdownOpen(false); // Close the dropdown when opening the modal
  };

  const closeSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionDropdownRef.current &&
        !optionDropdownRef.current.contains(event.target) &&
        !event.target.closest('.option-button')
      ) {
        setIsOptionDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleBackToProjects = () => {
    navigate('/projects');
  };

  useEffect(() => {
    const handleWordCountToggle = (e) => {
      setIsWordCountVisible(e.detail.show);
    };

    window.addEventListener('wordCountToggle', handleWordCountToggle);
    return () => window.removeEventListener('wordCountToggle', handleWordCountToggle);
  }, []);

  return (
    <div className="horizontal-header">
      <div className="header-project">
        <button 
          className="back-to-projects" 
          onClick={handleBackToProjects}
        >
          ← Projects
        </button>
        <div className="divider"></div>
        <input 
          type="text" 
          placeholder="Project Name" 
          id="project-name" 
          autoComplete="off" 
        />
        <div className="divider"></div>
        <div className="header-project-buttons">
          <button className="history-button">
            <ClockIcon />
          </button>
          <button className="import-button">
            <ImportIcon />
          </button>
          <button className="view-button">
            <ViewIcon />
          </button>
        </div>
      </div>

      <div className="header-right">
        <CharacterCount 
          count={characterCount} 
          isSaving={isSaving} 
          style={{ display: isWordCountVisible ? 'flex' : 'none' }} 
        />
        <button className="profile-icon-header"></button>
        <div className="divider"></div>
        <button className="option-button" onClick={toggleOptionDropdown}>
          <OptionIcon />
        </button>
        {isOptionDropdownOpen && (
          <div ref={optionDropdownRef} className="option-dropdown">
            <button className="settings-button" onClick={openSettingsModal}>Settings</button>
            <button className="export-button" onClick={() => {/* Action */}}>Export</button>
            {/* Add more menu items as needed */}
          </div>
        )}
      </div>

      {/* Modal Component */}
      <Modal isOpen={isSettingsModalOpen} onClose={closeSettingsModal} />
    </div>
  );
};

export default HorizontalHeader;
