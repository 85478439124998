import { supabase } from '../supabaseClient';

const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const getAuthHeaders = async () => {
  const { data: { session }, error } = await supabase.auth.getSession();
  
  if (error || !session) {
    throw new Error('Authentication required');
  }

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${session.access_token}`,
    'Refresh-Token': session.refresh_token,
  };
};

const streamToText = async (response) => {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let result = '';

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    result += decoder.decode(value, { stream: true });
  }

  return result.split('---').filter(text => text.trim() !== '');
};

export const bubbleMenuRewrite = async (text, option, projectId) => {
  try {
    let endpoint;
    switch (option) {
      case 'rephrase':
        endpoint = 'rephrase_stream';
        break;
      case 'simplify':
        endpoint = 'shorten_stream';
        break;
      case 'distinctive':
        endpoint = 'distinctive_stream';
        break;
      case 'emotional':
        endpoint = 'emotional_stream';
        break;
      case 'romantic':
        endpoint = 'romantic_stream';
        break;
      case 'happy':
        endpoint = 'happy_stream';
        break;
      case 'sad':
        endpoint = 'sad_stream';
        break;
      case 'angry':
        endpoint = 'angry_stream';
        break;
      case 'scared':
        endpoint = 'scared_stream';
        break;
      case 'tender':
        endpoint = 'tender_stream';
        break;
      case 'excited':
        endpoint = 'excited_stream';
        break;
      case 'foreshadow':
        endpoint = 'foreshadow_stream';
        break;
      case 'enhanceDialogue':
        endpoint = 'enhance_dialogue_stream';
        break;
      case 'strengthenVoice':
        endpoint = 'strengthen_voice_stream';
        break;
      case 'heightenEmotion':
        endpoint = 'heighten_emotion_stream';
        break;
      case 'vividness':
        endpoint = 'vividness_stream';
        break;
      default:
        throw new Error('Invalid rewrite option');
    }

    const headers = await getAuthHeaders();
    
    const response = await fetch(`${AI_API_URL}/${endpoint}`, {
      method: 'POST',
      headers,
      credentials: 'include',
      body: JSON.stringify({
        text,
        num_versions: 4,
        project_id: projectId
      }),
    });

    if (response.status === 401) {
      // Token expired, try to refresh session
      const { data: { session }, error } = await supabase.auth.refreshSession();
      if (error || !session) {
        throw new Error('Session refresh failed');
      }
      
      // Retry the request with new token
      const newHeaders = await getAuthHeaders();
      const retryResponse = await fetch(`${AI_API_URL}/${endpoint}`, {
        method: 'POST',
        headers: newHeaders,
        credentials: 'include',
        body: JSON.stringify({
          text,
          num_versions: 4,
          project_id: projectId
        }),
      });
      
      return await streamToText(retryResponse);
    }

    return await streamToText(response);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const fetchSavedRewrites = async (projectId) => {
  try {
    const headers = await getAuthHeaders();
    const response = await fetch(`${AI_API_URL}/rewrites/${projectId}`, {
      method: 'GET',
      headers,
      credentials: 'include',
    });

    if (response.status === 401) {
      // Token expired, try to refresh session
      const { data: { session }, error } = await supabase.auth.refreshSession();
      if (error || !session) {
        throw new Error('Session refresh failed');
      }
      
      // Retry with new token
      const newHeaders = await getAuthHeaders();
      const retryResponse = await fetch(`${AI_API_URL}/rewrites/${projectId}`, {
        method: 'GET',
        headers: newHeaders,
        credentials: 'include',
      });
      
      if (!retryResponse.ok) {
        throw new Error('Network response was not ok');
      }
      
      return await retryResponse.json();
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching saved rewrites:', error);
    throw error;
  }
};
