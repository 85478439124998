import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import './ProjectSelection.css';
import './LeftSidebar.css';
import { PlotIcon, EllipseIcon, LightModeIcon, OptionIcon, EllipseSpacer, SingleEllipse, CardHomeIcon, CardBookmarkIcon } from './SVGLibrary';
import { v4 as uuidv4 } from 'uuid'; 
import { generateThumbnail } from '../services/thumbnailService';
import { formatDistanceToNow } from 'date-fns';
import slugify from 'slugify'; 
import { ProfileIcon, DownArrowIcon, RightArrowIcon, ThemePlace } from './SVGLibrary';
import { useTheme } from '../ThemeContext';
import Modal from './Modal';
import ThemeMenu from './ThemeMenu';

const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const ProjectSelection = ({ user }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('recents');
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isThemesSideMenuOpen, setIsThemesSideMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const themesSideMenuRef = useRef(null);
  const { currentTheme, updateTheme } = useTheme();
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const optionDropdownRef = useRef(null);
  const [activeCardMenu, setActiveCardMenu] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, [user]); // Add user as a dependency

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !themesSideMenuRef.current?.contains(event.target) &&
        !event.target.closest('.profile-button')
      ) {
        setIsProfileDropdownOpen(false);
        setIsThemesSideMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionDropdownRef.current &&
        !optionDropdownRef.current.contains(event.target) &&
        !event.target.closest('.option-button')
      ) {
        setIsOptionDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('id, user_id, title, slug, description, content, image, updated_at') 
        .eq('user_id', user.id);

      if (error) throw error;

      console.log('Fetched projects:', data);
      setProjects(data);
      setLoading(false);

      // Generate thumbnails for projects without images
      data.forEach(project => {
        if (!project.image) {
          generateThumbnailForProject(project.id);
        }
      });
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Failed to load projects. Please try again.');
      setLoading(false);
    }
  };

  const generateThumbnailForProject = async (projectId) => {
    try {
      const thumbnailUrl = await generateThumbnail(projectId);
      if (thumbnailUrl) {
        // Update the project in the local state
        setProjects(prevProjects => 
          prevProjects.map(project => 
            project.id === projectId ? { ...project, image: thumbnailUrl } : project
          )
        );
      }
    } catch (error) {
      console.error(`Error generating thumbnail for project ${projectId}:`, error);
    }
  };

  const createNewProject = async () => {
    try {
      const projectId = uuidv4();
      const defaultTitle = 'New Project';
      const slugBase = slugify(defaultTitle, { lower: true });
      const uniqueIdPart = projectId.replace(/-/g, '').substring(0, 12);
      let slug = `${slugBase}-${uniqueIdPart}`;

      // Ensure slug uniqueness
      let slugExists = true;
      let attempt = 1;
      while (slugExists) {
        const { data: existingProject } = await supabase
          .from('projects')
          .select('id')
          .eq('slug', slug)
          .single();

        if (existingProject) {
          slug = `${slugBase}-${uniqueIdPart}-${attempt}`;
          attempt += 1;
        } else {
          slugExists = false;
        }
      }

      // Create project first
      const newProject = {
        id: projectId,
        user_id: user.id,
        title: defaultTitle,
        slug: slug,
        description: 'A new writing project',
        content: { type: "doc", content: [{ type: "paragraph" }] },
        updated_at: new Date().toISOString()
      };

      const { data: project, error: projectError } = await supabase
        .from('projects')
        .insert([newProject])
        .select('*')
        .single();

      if (projectError) throw projectError;

      // Now create the associated world
      const { data: world, error: worldError } = await supabase
        .from('worlds')
        .insert({
          user_id: user.id,
          project_id: project.id,  // Now we have the project.id
          name: 'My World'
        })
        .select()
        .single();

      if (worldError) throw worldError;

      // Update project with world_id
      const { error: updateError } = await supabase
        .from('projects')
        .update({ world_id: world.id })
        .eq('id', project.id);

      if (updateError) throw updateError;

      if (project) {
        setProjects(prevProjects => {
          const updatedProjects = [...prevProjects, project];
          return updatedProjects;
        });

        generateThumbnailForProject(project.id);

        setTimeout(() => {
          navigate(`/projects/${project.slug}`);
        }, 0);
      } else {
        console.error('New project data is null');
      }
    } catch (error) {
      console.error('Error creating new project:', error);
    }
  };

  const handleProjectClick = (projectSlug) => {
    navigate(`/projects/${projectSlug}`);
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/'); // Redirect to home or login page after sign out
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
    if (!isProfileDropdownOpen) {
      setIsThemesSideMenuOpen(false);
    }
  };

  const toggleThemesSideMenu = (event) => {
    event.stopPropagation();
    setIsThemesSideMenuOpen(!isThemesSideMenuOpen);
  };

  const toggleOptionDropdown = () => {
    setIsOptionDropdownOpen(!isOptionDropdownOpen);
  };

  const openSettingsModal = () => {
    setIsSettingsModalOpen(true);
    setIsOptionDropdownOpen(false);
  };

  const closeSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  const deleteProject = async (projectId, event) => {
    event.stopPropagation(); // Prevent card click event
    
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        const { error } = await supabase
          .from('projects')
          .delete()
          .eq('id', projectId);

        if (error) throw error;

        // Update local state to remove the deleted project
        setProjects(prevProjects => 
          prevProjects.filter(project => project.id !== projectId)
        );
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    }
  };

  const toggleCardMenu = (projectId, event) => {
    event.stopPropagation();
    setActiveCardMenu(activeCardMenu === projectId ? null : projectId);
  };

  if (loading) {
    return <div>Loading projects...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={`project-selection ${currentTheme}-theme`}>
      <div className="project-selection-sidebar">
        <header className="project-selection-header">
          <button className="profile-button" onClick={toggleProfileDropdown}>
            <ProfileIcon />
            <span className='user-name'>{user?.user_metadata?.full_name || 'User'}</span>
            <DownArrowIcon/>
          </button>
          {isProfileDropdownOpen && (
            <div ref={dropdownRef} className="profile-dropdown">
              <div className="profile-dropdown-header">
                <span>{user?.user_metadata?.full_name || 'User'}</span>
                <span>{user?.email}</span>
              </div>
              <button className='dropdown-library' onClick={() => {}}>My Library</button>
              <button className='dropdown-preferences' onClick={() => {}}>Preferences <RightArrowIcon/></button>
              <ThemeMenu 
                isProfileDropdownOpen={isProfileDropdownOpen}
                isThemesSideMenuOpen={isThemesSideMenuOpen}
                toggleThemesSideMenu={toggleThemesSideMenu}
                themesSideMenuRef={themesSideMenuRef}
              />
              <button className='dropdown-settings' onClick={() => {}}>Settings</button>
              <button className='dropdown-logout' onClick={handleSignOut}>Logout</button>
            </div>
          )}
        </header>
        <main className="project-selection-main">
          <h2 className='project-name'><span>Library</span></h2>
          <div className='project-options'>
            <div className="project-dropdown">
              <button className={`project-button ${activeSection === 'recents' ? 'active' : ''}`} onClick={() => setActiveSection('recents')}>
                <span className='page-button-text'><CardHomeIcon />Recents</span>
              </button>
            </div>
            <div className="project-dropdown">
              <button className={`project-button ${activeSection === 'bookmarks' ? 'active' : ''}`} onClick={() => setActiveSection('bookmarks')}>
                <span className='page-button-text'><CardBookmarkIcon />Bookmarks</span>
              </button>
            </div>
            {/* Add other options following the same pattern */}
          </div>
        </main>
        <footer className="project-selection-footer">
            <button className="footer-button">
                <PlotIcon />
                <span>Trash</span>
            </button>
         
        </footer>
      </div>
      <div className="project-selection-content">
        <div className="project-content-header">
          <div className="header-actions">
          <button className='project-create-button' onClick={createNewProject}>
            <span>Create</span>
          </button>
            <button className="sign-out-button" onClick={handleSignOut}>Sign Out</button>
            <div className="option-button-container">
              <button className="header-button option-button" onClick={toggleOptionDropdown}>
                <OptionIcon />
              </button>
              {isOptionDropdownOpen && (
                <div ref={optionDropdownRef} className="option-dropdown">
                  <button className="settings-button" onClick={openSettingsModal}>Settings</button>
                  <button className="export-button" onClick={() => {/* Action */}}>Export</button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="project-card-wrapper">

            {projects.map((project) => (
              project && (
                <div key={project.id} className="project-card" onClick={() => handleProjectClick(project.slug)}>
                  <div className='project-card-image'>
                    {project.image ? (
                      <img src={project.image} alt={project.title || 'Project thumbnail'} />
                    ) : (
                      <div className="thumbnail-placeholder">Generating...</div>
                    )}
                  </div>
                  <div className='project-card-info'>
                    <div className="project-card-title">
                      <span>{project.title}</span>
                    </div>
                    <div className="card-footer">
                      <div className="card-edited">
                        <span className="last-updated">{formatRelativeTime(project.updated_at)}</span>
                      </div>
                      <div className="card-options">
                        <button 
                          className="card-option card-option-home"
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add home functionality
                          }}
                        >
                          <CardHomeIcon />
                        </button>
                        <button 
                          className="card-option card-option-bookmark"
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add bookmark functionality
                          }}
                        >
                          <CardBookmarkIcon />
                        </button>
                        <div className="card-option-menu">
                          <button 
                            className="card-option card-option-ellipse"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleCardMenu(project.id, e);
                            }}
                          >
                            <EllipseIcon />
                          </button>
                          {activeCardMenu === project.id && (
                            <div className="card-dropdown-menu">
                              <button onClick={(e) => {
                                e.stopPropagation();
                                // Add favorite functionality
                              }}>
                                Favourite
                              </button>
                              <button onClick={(e) => deleteProject(project.id, e)}>
                                Delete project
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))}
        </div>
        
      </div>
      <Modal isOpen={isSettingsModalOpen} onClose={closeSettingsModal} />
      
    </div>
  );
};

export default ProjectSelection;

// Add this function outside of your component
function formatRelativeTime(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);

  if (diffInSeconds < 60) return 'just now';
  if (diffInMinutes < 60) return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
  if (diffInHours < 24) return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
  if (diffInDays < 7) return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
  if (diffInWeeks < 4) return `${diffInWeeks} ${diffInWeeks === 1 ? 'week' : 'weeks'} ago`;
  if (diffInMonths < 12) return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
  return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
}
