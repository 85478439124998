import { supabase } from '../supabaseClient';

const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const getAuthHeaders = async () => {
  const { data: { session }, error } = await supabase.auth.getSession();
  
  if (error || !session) {
    throw new Error('Authentication required');
  }

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${session.access_token}`,
    'Refresh-Token': session.refresh_token,
    'Accept': 'application/json'
  };
};

const handleAuthenticatedRequest = async (url, body) => {
  const headers = await getAuthHeaders();
  const response = await fetch(url, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(body)
  });

  if (response.status === 401) {
    // Token expired, try to refresh session
    const { data: { session }, error } = await supabase.auth.refreshSession();
    if (error || !session) {
      throw new Error('Session refresh failed');
    }
    
    // Retry with new token
    const newHeaders = await getAuthHeaders();
    const retryResponse = await fetch(url, {
      method: 'POST',
      headers: newHeaders,
      credentials: 'include',
      body: JSON.stringify(body)
    });
    
    return retryResponse;
  }

  return response;
};

export const analyzeStory = async (action, projectId, visibleText) => {
  console.log('Story Analysis Request:', { 
    action, 
    projectId,
    textLength: visibleText?.length || 0 
  });
  
  try {
    const response = await handleAuthenticatedRequest(
      `${AI_API_URL}/writing-assistant/analyze/story`,
      {
        action,
        project_id: projectId,
        visible_text: visibleText
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Story Analysis Error:', {
        status: response.status,
        error: errorText,
        action
      });
      throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Story Analysis Success:', {
      action,
      suggestionCount: data.suggestions?.length || 0
    });
    return data.suggestions || [];
  } catch (error) {
    console.error('Story Analysis Failed:', {
      action,
      error: error.message,
      stack: error.stack
    });
    throw error;
  }
};

export const analyzeStyle = async (action, projectId, visibleText) => {
  console.log('Style Analysis Request:', { 
    action, 
    projectId,
    textLength: visibleText?.length || 0 
  });
  
  try {
    const response = await handleAuthenticatedRequest(
      `${AI_API_URL}/writing-assistant/analyze/style`,
      {
        action,
        project_id: projectId,
        visible_text: visibleText
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Style Analysis Error:', {
        status: response.status,
        error: errorText,
        action
      });
      throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Style Analysis Success:', {
      action,
      suggestionCount: data.suggestions?.length || 0
    });
    return data.suggestions || [];
  } catch (error) {
    console.error('Style Analysis Failed:', {
      action,
      error: error.message,
      stack: error.stack
    });
    throw error;
  }
};

export const analyzeCreative = async (action, projectId, visibleText) => {
  console.log('Creative Analysis Request:', { 
    action, 
    projectId,
    textLength: visibleText?.length || 0 
  });
  
  try {
    const response = await handleAuthenticatedRequest(
      `${AI_API_URL}/writing-assistant/analyze/creative`,
      {
        action,
        project_id: projectId,
        visible_text: visibleText
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Creative Analysis Error:', {
        status: response.status,
        error: errorText,
        action
      });
      throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Creative Analysis Success:', {
      action,
      suggestionCount: data.suggestions?.length || 0
    });
    return data.suggestions || [];
  } catch (error) {
    console.error('Creative Analysis Failed:', {
      action,
      error: error.message,
      stack: error.stack
    });
    throw error;
  }
};
