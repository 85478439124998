import { supabase } from '../supabaseClient';

const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const getAuthHeaders = async () => {
  const { data: { session }, error } = await supabase.auth.getSession();
  
  if (error || !session) {
    throw new Error('Authentication required');
  }

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${session.access_token}`,
    'Refresh-Token': session.refresh_token,
  };
};

export const generateThumbnail = async (projectId) => {
  try {
    const headers = await getAuthHeaders();
    const response = await fetch(`${AI_API_URL}/generate_thumbnail`, {
      method: 'POST',
      headers,
      credentials: 'include',
      body: JSON.stringify({ project_id: projectId }),
    });

    if (response.status === 401) {
      // Token expired, try to refresh session
      const { data: { session }, error } = await supabase.auth.refreshSession();
      if (error || !session) {
        throw new Error('Session refresh failed');
      }
      
      // Retry with new token
      const newHeaders = await getAuthHeaders();
      const retryResponse = await fetch(`${AI_API_URL}/generate_thumbnail`, {
        method: 'POST',
        headers: newHeaders,
        credentials: 'include',
        body: JSON.stringify({ project_id: projectId }),
      });
      
      if (!retryResponse.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await retryResponse.json();
      return data.thumbnail_url;
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.thumbnail_url;
  } catch (error) {
    console.error('Error generating thumbnail:', error);
    throw error;
  }
};