import React, { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';
import './LeftSidebar.css';
import {AnthropicLogo, PlotIcon, LightModeIcon, WorldbuildingIcon, CloseIcon,StorycraftIcon, SageIcon, ComposeIcon, PlusIcon, InfoIcon, HistoryIcon, AccountIcon, ThemePlace, ProfileIcon, SidebarCollapse, RightArrowIcon,HomeButtonIcon, PageListIcon, HelperIcon, HeaderEllipseIcon, WorldGenreIcon, WorldStyleIcon, WorldIdeaIcon, WorldGeneralTooltip, WorldCharactersTooltip, WorldForgeTooltip, WorldSummaryTooltip, SummaryIcon, WorldBuilderIcon, OptionsPlusIcon, CharacterNameIcon, DownArrowIcon, EllipseIcon, UpdatesIcon } from './SVGLibrary';
import { useTheme } from '../ThemeContext';
import { generateOutline } from '../services/outlineService';
import ThemeMenu from './ThemeMenu';

const worldforgeItems = [
  { title: "Setting", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Key Event", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Culture ", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Government", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Organization", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Economy", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Religion", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Technology", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Conflict", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Geography", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "History", description: "Share your story's universe. Worldbuilder learns from." },
  { title: "Custom", description: "Share your story's universe. Worldbuilder learns from." }
].map(item => ({
  ...item,
  id: Date.now() + Math.random() // Ensure unique IDs
}));

const LeftSidebar = ({ setIsLeftSidebarExtended, projectId, worldId: initialWorldId }) => {
  const [isCollapsed] = useState(false);
  const [extendedContent, setExtendedContent] = useState(null);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isThemesSideMenuOpen, setIsThemesSideMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedWorldbuildingOption, setSelectedWorldbuildingOption] = useState('general'); // Default to 'general'
  const [selectedWorldforgeOption, setSelectedWorldforgeOption] = useState(null);
  const [tooltipContent, setTooltipContent] = useState(
    "Tell us about your story's universe. Worldbuilder learns from your details to enhance your writing experience. It powers smarter rewrites and helps maintain consistency throughout your narrative. The more you share, the better it understands your unique storytelling world."
  );
  const [tooltipBackground, setTooltipBackground] = useState("rgba(124, 168, 114, 0.10)");
  const [tooltipIcon, setTooltipIcon] = useState(WorldGeneralTooltip);
  const [worldId, setWorldId] = useState(initialWorldId);
  const [worldData, setWorldData] = useState({});
  const [characters, setCharacters] = useState([]);
  const saveTimeout = useRef(null);
  const dropdownRef = useRef(null);
const themesSideMenuRef = useRef(null);
const { currentTheme, updateTheme } = useTheme();
const [selectedSageOption, setSelectedSageOption] = useState('models'); // Default to 'models'
const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
const [selectedModel, setSelectedModel] = useState(null);
const [currentModel, setCurrentModel] = useState(null);
const [openDropdown, setOpenDropdown] = useState(null);
const [characterContainers, setCharacterContainers] = useState([{ id: 1 }]);
const [worldforgeContainers, setWorldforgeContainers] = useState([
  { 
    id: Date.now(),
    title: "Setting"
  }
]);
const [isMainContentHidden, setIsMainContentHidden] = useState(false);
const sidebarRef = useRef(null);
const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState(null);
const [editingCharacterId, setEditingCharacterId] = useState(null);
const [editingCharacterName, setEditingCharacterName] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    fetchUser();
  }, []);

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
    if (!isProfileDropdownOpen) {
      setIsThemesSideMenuOpen(false);
    }
  };

  const toggleThemesSideMenu = (event) => {
    console.log('Toggling theme menu');
    event.stopPropagation();
    console.log('Current state:', isThemesSideMenuOpen);
    setIsThemesSideMenuOpen(!isThemesSideMenuOpen);
    console.log('New state:', !isThemesSideMenuOpen);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    // Handle logout logic (e.g., redirect to login page)
  };

  useEffect(() => {
    const fetchOrCreateWorld = async () => {
      if (!projectId) {
        console.log('No project ID available');
        return;
      }
      
      try {
        setIsLoading(true);
        setError(null);
        
        // First, get the project to find its world_id
        const { data: project, error: projectError } = await supabase
          .from('projects')
          .select('world_id')
          .eq('id', projectId)
          .single();

        if (projectError) {
          throw projectError;
        }

        if (!project?.world_id) {
          console.error('No world_id found for project:', projectId);
          return;
        }

        // Set the world ID from the project
        setWorldId(project.world_id);
        
        // Fetch the world details
        await fetchWorldDetails(project.world_id);

      } catch (err) {
        console.error('Error fetching world:', err);
        setError('Failed to fetch world data: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrCreateWorld();
  }, [projectId]);

  const fetchWorldDetails = async (worldId) => {
    console.log('Fetching world details for:', worldId);
    const { data, error } = await supabase
      .from('world_details')
      .select('*')
      .eq('world_id', worldId);

    if (error) {
      console.error('Error fetching world details:', error);
      return;
    }

    // Transform the data into the correct structure
    const transformedData = data.reduce((acc, item) => {
      acc[item.category] = item.data;
      return acc;
    }, {});

    console.log('Transformed world data:', transformedData);
    setWorldData(transformedData);

    // Reconstruct containers for characters
    if (transformedData.characters) {
      const characterIds = Object.keys(transformedData.characters)
        .filter(key => key.startsWith('character_'))
        .map(key => key.replace('character_', ''));
      
      setCharacterContainers(characterIds.map(id => ({ id })));
    }

    // Reconstruct containers for worldforge
    if (transformedData.worldforge) {
      const worldforgeElements = Object.entries(transformedData.worldforge)
        .reduce((acc, [key, value]) => {
          const [type, id] = key.split('_');
          if (id) {
            acc.push({
              id,
              title: type.charAt(0).toUpperCase() + type.slice(1)
            });
          }
          return acc;
        }, []);
      
      setWorldforgeContainers(worldforgeElements);
    }
  };

  const saveWorldDetail = async (category, data) => {
    console.group('saveWorldDetail');
    console.log('Called with:', { category, data, worldId });
    
    if (!worldId) {
      console.error('No world ID available');
      console.groupEnd();
      return;
    }

    try {
      console.log('Fetching existing record from Supabase...');
      const { data: existingData, error: fetchError } = await supabase
        .from('world_details')
        .select('*')
        .eq('world_id', worldId)
        .eq('category', category)
        .maybeSingle();

      console.log('Supabase response:', { existingData, fetchError });

      if (fetchError) {
        console.error('Fetch error:', fetchError);
        throw fetchError;
      }

      let saveResult;
      if (existingData) {
        console.log('Updating existing record...');
        saveResult = await supabase
          .from('world_details')
          .update({ data })
          .eq('id', existingData.id);
      } else {
        console.log('Inserting new record...');
        saveResult = await supabase
          .from('world_details')
          .insert({ world_id: worldId, category, data });
      }

      console.log('Save result:', saveResult);

      console.log('Calling embeddings API...');
      const response = await fetch('https://pagerift-writer.ue.r.appspot.com/api/generate_embeddings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ world_id: worldId }),
      });

      console.log('Embeddings API response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Embeddings API error:', errorText);
        throw new Error(`Embedding generation failed: ${response.status} ${errorText}`);
      }

      const result = await response.json();
      console.log('Embeddings generation result:', result);
      
    } catch (error) {
      console.error('Error in saveWorldDetail:', error);
      console.error('Error stack:', error.stack);
    }
    console.groupEnd();
  };

  const handleWorldbuildingChange = (category, field, value) => {
    console.group('handleWorldbuildingChange');
    console.log('Called with:', { category, field, value });
    console.log('Current worldId:', worldId);
    
    const updatedData = {
      ...worldData[category],
      [field]: value
    };
    
    console.log('Updated data structure:', updatedData);
    
    setWorldData(prevData => {
      console.log('Previous worldData:', prevData);
      const newData = {
        ...prevData,
        [category]: updatedData
      };
      console.log('New worldData state:', newData);
      return newData;
    });

    if (saveTimeout.current) {
      console.log('Clearing previous save timeout');
      clearTimeout(saveTimeout.current);
    }
    
    console.log('Setting new save timeout');
    saveTimeout.current = setTimeout(() => {
      console.log('Save timeout triggered for:', { category, updatedData });
      saveWorldDetail(category, updatedData);
    }, 500);
    console.groupEnd();
  };

  const toggleDropdown = (content) => {
    const editorWrapper = document.querySelector('.editor-right-wrapper');
    
    if (openDropdown === content) {
      setOpenDropdown(null);
      setIsLeftSidebarExtended(false);
      setExtendedContent(null);
      editorWrapper?.classList.remove('hidden');
    } else {
      setOpenDropdown(content);
      setIsLeftSidebarExtended(true);
      setExtendedContent(content);
      editorWrapper?.classList.add('hidden');
      if (content === 'worldbuilding') {
        setSelectedWorldbuildingOption('general');
      } else if (content === 'sage') {
        setSelectedSageOption('models');
      }
    }
  };

  const handleWorldbuildingOptionClick = (option) => {
    const section = document.querySelector(`[data-section="${option}"]`);
    if (section) {
      section.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'nearest',
        inline: 'nearest'
      });
    }
  };

  const handleWorldforgeOptionClick = (option) => {
    setSelectedWorldforgeOption(prevOption => prevOption === option ? null : option);
  };

  const autoResizeTextarea = (event) => {
    const textarea = event.target;
    // Reset height temporarily to get the correct scrollHeight
    textarea.style.height = 'auto';
    // Set the height to the scrollHeight
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const addCharacter = () => {
    const newCharacter = { id: Date.now() };
    setCharacters(prevCharacters => [...prevCharacters, newCharacter]);
    handleWorldbuildingChange('characters', characters.length.toString(), newCharacter);
  };

  const handleSageOptionClick = (option) => {
    setSelectedSageOption(option);
  };

  const toggleAdvancedOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleModelSelection = (model) => {
    console.log(`Model selected: ${model}`);
    setSelectedModel(model);
  };

  const handleUseModel = async () => {
    console.log(`Current selected model: ${selectedModel}`);
    if (selectedModel) {
      console.log(`Attempting to set model to: ${selectedModel}`);
      try {
        const response = await fetch('https://pagerift-writer.ue.r.appspot.com/api/set_rewrite_model', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ model: selectedModel }),
          credentials: 'include',
        });
  
        console.log('Response status:', response.status);
        const responseData = await response.json();
        console.log('Response data:', responseData);
  
        if (response.ok) {
          console.log(responseData.message);
          setCurrentModel(selectedModel);
        } else {
          console.error('Failed to set model:', responseData);
        }
      } catch (error) {
        console.error('Error setting model:', error);
      }
    } else {
      console.log('No model selected');
    }
  };

  useEffect(() => {
    const fetchCurrentModel = async () => {
      try {
        const response = await fetch('https://pagerift-writer.ue.r.appspot.com/api/current_models');
        if (response.ok) {
          const data = await response.json();
          setCurrentModel(data.rewrite_model);
        }
      } catch (error) {
        console.error('Error fetching current model:', error);
      }
    };

    fetchCurrentModel();
  }, []);

  const toggleWorldforgeAddMenu = (event) => {
    event.stopPropagation();
    const menu = event.currentTarget.nextElementSibling;
    const isActive = menu.classList.contains('active');

    const closeMenu = (e) => {
      if (!menu.contains(e.target) && e.target !== event.currentTarget) {
        menu.classList.remove('active');
        document.removeEventListener('click', closeMenu);
      }
    };

    if (!isActive) {
      menu.classList.add('active');
      setTimeout(() => {
        document.addEventListener('click', closeMenu);
      }, 0);
    } else {
      menu.classList.remove('active');
      document.removeEventListener('click', closeMenu);
    }
  };

  useEffect(() => {
    const textareas = document.querySelectorAll('.worldbuilding-field textarea');
    
    textareas.forEach(textarea => {
      // Initial resize
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
      
      // Add input event listener
      textarea.addEventListener('input', autoResizeTextarea);
    });

    // Cleanup
    return () => {
      textareas.forEach(textarea => {
        textarea.removeEventListener('input', autoResizeTextarea);
      });
    };
  }, [selectedWorldbuildingOption]); // Re-run when switching between options

  const addCharacterContainer = () => {
    setCharacterContainers(prev => [...prev, { id: Date.now() }]);
  };

  const addWorldforgeContainer = (title) => {
    setWorldforgeContainers(prev => [...prev, {
      id: Date.now(),
      title: title
    }]);
    // Close the menu after selection
    const menu = document.querySelector('.worldforge-add-menu');
    if (menu) menu.classList.remove('active');
  };

  const handleGenerateOutline = async () => {
    try {
      const result = await generateOutline(worldId, worldData);
      if (result.success) {
        // If the result is a string containing JSON, parse it
        let outlineContent = result.outline;
        if (typeof outlineContent === 'string' && outlineContent.startsWith('{')) {
          try {
            const parsed = JSON.parse(outlineContent);
            outlineContent = parsed.outline;
          } catch (e) {
            console.error('Error parsing outline JSON:', e);
          }
        }
        
        handleWorldbuildingChange('outline', 'content', outlineContent);
      } else {
        if (result.missing_sections) {
          alert(`Please complete the following sections first: ${result.missing_sections.join(', ')}`);
        } else {
          alert(result.error || 'Failed to generate outline');
        }
      }
    } catch (error) {
      console.error('Error generating outline:', error);
      alert('Failed to generate outline. Please try again.');
    }
  };

  useEffect(() => {
    if (!extendedContent) return;

    const timer = setTimeout(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const section = entry.target.getAttribute('data-section');
              const dropdownType = entry.target.closest('.sage-content, .worldbuilding-content')
                ?.classList.contains('sage-content') ? 'sage' : 'worldbuilding';
              
              const button = document.querySelector(
                `.${dropdownType}-dropdown-list button[data-option="${section}"]`
              );
              
              if (button) {
                document.querySelectorAll(`.${dropdownType}-dropdown-list button`)
                  .forEach(btn => btn.classList.remove('active'));
                button.classList.add('active');
              }
            }
          });
        },
        { 
          root: document.querySelector(
            extendedContent === 'sage' ? '.sage-option-content' : '.worldbuilding-option-content'
          ),
          threshold: 0.5,
          rootMargin: '0px'
        }
      );

      document.querySelectorAll('[data-section]').forEach((section) => {
        observer.observe(section);
      });

      return () => observer.disconnect();
    }, 100);

    return () => clearTimeout(timer);
  }, [extendedContent]);

  useEffect(() => {
    const fetchWorldDetails = async () => {
      if (!initialWorldId) {
        console.log('No world ID available');
        return;
      }
      
      try {
        setIsLoading(true);
        setError(null);
        
        // Set the world ID from props
        setWorldId(initialWorldId);
        
        // Fetch the world details
        const { data, error } = await supabase
          .from('world_details')
          .select('*')
          .eq('world_id', initialWorldId);

        if (error) {
          throw error;
        }

        // Transform the data into the correct structure
        const transformedData = data.reduce((acc, item) => {
          acc[item.category] = item.data;
          return acc;
        }, {});

        console.log('Transformed world data:', transformedData);
        setWorldData(transformedData);

      } catch (err) {
        console.error('Error fetching world:', err);
        setError('Failed to fetch world data: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorldDetails();
  }, [initialWorldId]); // Depend on initialWorldId instead of projectId

  // Add useEffect for click outside handling
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsProfileDropdownOpen(false);
      }
      if (themesSideMenuRef.current && !themesSideMenuRef.current.contains(event.target)) {
        setIsThemesSideMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCharacterNameUpdate = async (containerId, newName) => {
    try {
      // Update local state
      handleWorldbuildingChange('characters', `character_${containerId}`, {
        ...worldData.characters?.[`character_${containerId}`],
        name: newName
      });
      
      // Clear editing state
      setEditingCharacterId(null);
      setEditingCharacterName('');
    } catch (error) {
      console.error('Error updating character name:', error);
    }
  };

  return (
    <div className={`left-sidebar ${extendedContent ? 'extended' : ''}`}>
      <div className={`left-sidebar-container ${isCollapsed ? 'collapsed' : ''}`}>
        <header className="left-sidebar-header">
          <button className="profile-button" onClick={toggleProfileDropdown}>
            <ProfileIcon />
            <span className='user-name'>{user?.user_metadata?.full_name || 'User'}</span>
            <DownArrowIcon/>
          </button>
          {isProfileDropdownOpen && (
            <div ref={dropdownRef} className="profile-dropdown">
              <div className="profile-dropdown-container">
                <div className="profile-dropdown-header">
                  <span>{user?.user_metadata?.full_name || 'User'}</span>
                  <span>{user?.email}</span>
                </div>
                <div className="profile-dropdown-divider"></div>
                <div className="profile-dropdown-buttons">
                  <button className='dropdown-library' onClick={() => {}}>My Library</button>
                  <button className='dropdown-preferences' onClick={() => {}}>Preferences</button>
                  <ThemeMenu 
                    isProfileDropdownOpen={isProfileDropdownOpen}
                    isThemesSideMenuOpen={isThemesSideMenuOpen}
                    toggleThemesSideMenu={toggleThemesSideMenu}
                    themesSideMenuRef={themesSideMenuRef}
                  />
                  <button className='dropdown-settings' onClick={() => {}}>Settings</button>
                  <button className='dropdown-logout' onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
          )}
        </header>
        <main className='left-sidebar-main'>
          <h2 className='project-name'><span>Pages</span></h2>
          <div className='page-list'>
            <button className='page-button'><PageListIcon /> <span className='page-name'>The War</span></button>
            <button className='page-button'><PageListIcon /> <span className='page-name'>Duneland</span></button>
          </div>

          <div className='left-sidebar-options'>
            <div className="worldbuilding-dropdown">
              <button className={`worldbuilding-button ${openDropdown === 'worldbuilding' ? 'active' : ''}`} onClick={() => toggleDropdown('worldbuilding')}>
                <span className='page-button-text'> <StorycraftIcon />Storycraft</span>
              </button>
              
            </div>
            <div className="sage-dropdown">
              <button className={`sage-button ${openDropdown === 'sage' ? 'active' : ''}`} onClick={() => toggleDropdown('sage')}>
                <span className='page-button-text'> <SageIcon />Sage</span>
              </button>
              
            </div>
            <div className="history-dropdown">
              <button className={`history-button ${openDropdown === 'history' ? 'active' : ''}`} onClick={() => toggleDropdown('history')}>
                <span className='page-button-text'><HistoryIcon/>History</span>
              </button>
            </div>
          </div>
        </main>

        <footer className='left-sidebar-footer'>
          <div className="updates-box">
            <img src='/Grid.png' alt='grid'/>
            <div className="updates-close-button">
              <CloseIcon/>
            </div>
            <div className="updates-icon">
              <UpdatesIcon/>
            </div>
            <h2>See latest updates</h2>
            <div className="updates-button">
              <span className='updates-text'>Go to Updates</span>
            </div>
          </div>
        </footer>
      </div>
      {extendedContent && (
        <div 
          className={`left-sidebar-extended ${extendedContent ? 'open' : ''}`}
          ref={sidebarRef}
        >
          {extendedContent === 'sage' && (
            <div className="sage-content">
              <div className="sage-tooltip-container">
                <div className='sage-title'>
                  <span>Sage</span>
                  <small>Share your story's universe. Worldbuilder learns from your details to enhance your writing experience. It powers smarter rewrites and maintains consistency throughout your narrative.</small>
                </div>
                {openDropdown === 'sage' && (
                  <ul className="dropdown-list sage-dropdown-list">
                    <li><button>Emotional</button></li>
                    <li><button>Sensual</button></li>
                    <li><button onClick={() => handleSageOptionClick('models')}>Models</button></li>
                  </ul>
                )}
                {selectedSageOption && (
                  <div className="sage-option-content">
                    {selectedSageOption === 'models' && (
                      <div className="sage-models-content">
                          
                        <div className="model-selection">
                        <div className="model-selection-header">
                          <div className="model-selection-header-title">
                            <span>Write</span>
                            <small>Customize your writer model</small>

                          </div>
                          <div className="model-selection-header-button">
                            <button className="model-modes-selection-button">
                              <span className="selected-model-category">Base models</span>
                              <DownArrowIcon/>
                            </button>
                            </div>
                          </div>
                          <ul className="model-item-options">
                            <li 
                              className={`model-item-option ${selectedModel === 'claude-3-opus-20240229' ? 'active' : ''}`}
                            >
                              <header className="model-item-option-header">
                                <div className="model-item-option-header-logo">
                                  <div className="anthropic-logo"><AnthropicLogo/></div>
                                  <div className="model-item-option-header-title">
                                    <span>Stellar</span>
                                    <small>Unlimited creative potential</small>
                                  </div>
                                </div>
                              <ul className="model-info-tags">
                                <li>Character depth analysis</li>
                                <li>Multilingual</li>
                                <li>Complex plot handling</li>
                                <li>Advanced reasoning</li>
                              </ul>
                              </header>
                              <div className="model-item-option-info">
                                <div className="model-item-option-info-title">
                                  <span>Base model - Claude 3 Opus</span>
                                  <small>Uncompromising quality and a greater reasoning ability for complex scenarios. Advanced language models.</small>
                                </div>
                                <button 
                                  className={`model-use-button ${currentModel === 'claude-3-opus-20240229' ? 'in-use' : ''}`}
                                  onClick={() => {
                                    handleModelSelection('claude-3-opus-20240229');
                                    handleUseModel();
                                  }}
                                >
                                  <span>{currentModel === 'claude-3-opus-20240229' ? 'Model in use' : 'Use model'}</span>
                                </button>
                              </div>
                            </li>
                            <li className={`model-item-option ${selectedModel === 'claude-3-sonnet-20240229' ? 'active' : ''}`} onClick={() => {
                              handleModelSelection('claude-3-sonnet-20240229');
                              handleUseModel();
                            }}>
                              <header className="model-item-option-header">
                                <div className="model-item-option-header-logo">
                                  <div className="anthropic-logo"><AnthropicLogo/></div>
                                  <div className="model-item-option-header-title">
                                    <span>Stellar</span> 
                                    <small>Enhanced language models</small>
                                  </div>
                                </div>
                                <ul className="model-info-tags">
                                  <li>Character depth analysis</li>
                                  <li>Multilingual</li>
                                  <li>Complex plot handling</li>
                                  <li>Advanced reasoning</li>
                                </ul>
                              </header>
                              <div className="model-item-option-info">
                                <div className="model-item-option-info-title">
                                  <span>Base model - Claude 3 Sonnet</span>
                                  <small>Uncompromising quality and a greater reasoning ability for complex scenarios. Advanced language models.</small>
                                </div>
                                <button 
                                  className={`model-use-button ${currentModel === 'claude-3-sonnet-20240229' ? 'in-use' : ''}`}
                                  onClick={() => {
                                    handleModelSelection('claude-3-sonnet-20240229');
                                    handleUseModel();
                                  }}
                                >
                                  <span>{currentModel === 'claude-3-sonnet-20240229' ? 'Model in use' : 'Use model'}</span>
                                </button>
                              </div>
                            </li>
                            <li className={`model-item-option ${selectedModel === 'claude-3-haiku-20240307' ? 'active' : ''}`} onClick={() => {
                              handleModelSelection('claude-3-haiku-20240307');
                              handleUseModel();
                            }}>
                              <header className="model-item-option-header">
                                <div className="model-item-option-header-logo">
                                  <div className="anthropic-logo"><AnthropicLogo/></div>
                                  <span>Essential & Affordable</span> 
                                </div>
                                <ul className="model-info-tags">
                                  <li>Character depth analysis</li>
                                  <li>Multilingual</li>
                                  <li>Complex plot handling</li>
                                  <li>Advanced reasoning</li>
                                </ul>
                              </header>
                              <div className="model-item-option-info">
                                <div className="model-item-option-info-title">
                                  <span>Base model - Claude 3 Haiku</span>
                                  <small>Uncompromising quality and a greater reasoning ability for complex scenarios. Advanced language models.</small>
                                </div>
                                <button 
                                  className={`model-use-button ${currentModel === 'claude-3-haiku-20240307' ? 'in-use' : ''}`}
                                  onClick={() => {
                                    handleModelSelection('claude-3-haiku-20240307');
                                    handleUseModel();
                                  }}
                                >
                                  <span>{currentModel === 'claude-3-haiku-20240307' ? 'Model in use' : 'Use model'}</span>
                                </button>
                              </div>
                            </li>
                            <li className={`model-item-option ${selectedModel === 'gpt-4-turbo-preview' ? 'active' : ''}`} onClick={() => {
                              handleModelSelection('gpt-4-turbo-preview');
                              handleUseModel();
                            }}>
                              <header className="model-item-option-header">
                                <div className="model-item-option-header-logo">
                                  <div className="openai-logo"></div>
                                  <span>Consistent Performance</span> 
                                </div>
                                <ul className="model-info-tags">
                                  <li>Character depth analysis</li>
                                  <li>Multilingual</li>
                                  <li>Complex plot handling</li>
                                  <li>Advanced reasoning</li>
                                </ul>
                              </header>
                              <div className="model-item-option-info">
                                <div className="model-item-option-info-title">
                                  <span>Base model - GPT-4 Turbo</span>
                                  <small>Uncompromising quality and a greater reasoning ability for complex scenarios. Advanced language models.</small>
                                </div>
                                <button 
                                  className={`model-use-button ${currentModel === 'gpt-4-turbo-preview' ? 'in-use' : ''}`}
                                  onClick={() => {
                                    handleModelSelection('gpt-4-turbo-preview');
                                    handleUseModel();
                                  }}
                                >
                                  <span>{currentModel === 'gpt-4-turbo-preview' ? 'Model in use' : 'Use model'}</span>
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {extendedContent === 'worldbuilding' && (
            <div className="worldbuilding-content">
              <div className="worldbuilding-option-content">
                {openDropdown === 'worldbuilding' && (
                  <ul className="dropdown-list worldbuilding-dropdown-list">
                    <li><button data-option="general" onClick={() => handleWorldbuildingOptionClick('general')}>General</button></li>
                    <li><button data-option="characters" onClick={() => handleWorldbuildingOptionClick('characters')}>Characters</button></li>
                    <li><button data-option="worldforge" onClick={() => handleWorldbuildingOptionClick('worldforge')}>World</button></li>
                    <li><button data-option="outline" onClick={() => handleWorldbuildingOptionClick('outline')}>Outline</button></li>
                  </ul>
                )}
                <div className="worldbuilding-main-content">
                  <div className="storycraft-title">
                    <div className="storycraft-title-header">
                      <span>Storycraft</span>
                      <span className="what-is-this">What is this?</span>
                    </div>
                    <small>Share your story's universe. Worldbuilder learns from your details to enhance your writing experience. It powers smarter rewrites and maintains consistency throughout your narrative.</small>
                  </div>
                  <div className="worldbuilding-general-content" data-section="general">
                    <div className="general-details-header worldbuilding-option-header">
                      <div className="general-details-header-title">
                        <span><WorldbuildingIcon/> Mindmap </span>
                      </div>
                    </div>
                    <div className="general-details-body worldbuilding-body">
                      <div className="general-details-header worldbuilding-header">
                        <span>General</span>
                        <EllipseIcon/>
                      </div>
                      <div className="general-details-fields worldbuilding-fields">
                        <div className="general-details-field worldbuilding-field">
                          <span className="worldbuilding-field-title">Braindump</span>
                          <textarea 
                            placeholder="Describe your writing style and tone..."
                            className="worldbuilding-field-textarea"
                            value={worldData.general?.braindump || ''}
                            onChange={(e) => {
                              autoResizeTextarea(e);
                              handleWorldbuildingChange('general', 'braindump', e.target.value);
                            }}
                          />
                        <footer className="worldbuilding-field-footer">
                          <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                        </footer>
                        </div>
                        <div className="general-details-field worldbuilding-field">
                          <span className="worldbuilding-field-title">Style</span>
                          <textarea 
                            placeholder="Select or describe the genre..."
                            className="worldbuilding-field-textarea"
                            value={worldData.general?.style || ''}
                            onChange={(e) => {
                              autoResizeTextarea(e);
                              handleWorldbuildingChange('general', 'style', e.target.value);
                            }}
                          />
                        <footer className="worldbuilding-field-footer">
                          <button className="worldbuilding-field-footer-button"><ComposeIcon/>Style</button>
                        </footer>
                        </div>
                        <div className="general-details-field worldbuilding-field">
                          <span className="worldbuilding-field-title">Genre</span>
                          <textarea 
                            placeholder="Select or describe the tone..."
                            className="worldbuilding-field-textarea"
                            value={worldData.general?.genre || ''}
                            onChange={(e) => {
                              autoResizeTextarea(e);
                              handleWorldbuildingChange('general', 'genre', e.target.value);
                            }}
                          />
                          <footer className="worldbuilding-field-footer">
                            <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                          </footer>
                        </div>
                        <div className="general-details-field worldbuilding-field">
                          <span className="worldbuilding-field-title">Idea Vault</span>
                          <textarea 
                            placeholder="Use this space to jot down any ideas, inspirations, or notes that support your story. This can include character details, plot points, settings, or any other elements you want to remember or explore. "
                            className="worldbuilding-field-textarea"
                            value={worldData.general?.ideaVault || ''}
                            onChange={(e) => {
                              autoResizeTextarea(e);
                              handleWorldbuildingChange('general', 'ideaVault', e.target.value);
                            }}
                          />
                        <footer className="worldbuilding-field-footer">
                          <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                        </footer>
                        </div> 
                      </div>
                    <div className="worldbuilding-general-footer general-details-footer">
                      <button className="general-details-footer-button worldbuilding-custom-button"> <PlusIcon/><span>Custom</span></button>
                    </div>
                    </div>
                  </div>

                  <div className="worldbuilding-characters-content" data-section="characters">
                    <div className="worldbuilding-characters-header worldbuilding-option-header">
                      <span><CharacterNameIcon/> Add Characters</span>
                      <button 
                        className="characters-add-button worldbuilding-add-button"
                        onClick={addCharacterContainer}
                      >
                        <PlusIcon/>
                        <span>Add Character</span>
                      </button>
                    </div>
                    {characterContainers.map((container) => (
                      <div key={container.id} className="worldbuilding-characters-container worldbuilding-body">
                        <div className="worldbuilding-characters-header worldbuilding-header">
                          <span>
                            
                            {editingCharacterId === container.id ? (
                              <input
                                type="text"
                                value={editingCharacterName}
                                onChange={(e) => setEditingCharacterName(e.target.value)}
                                onBlur={() => handleCharacterNameUpdate(container.id, editingCharacterName)}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    handleCharacterNameUpdate(container.id, editingCharacterName);
                                  }
                                }}
                                autoFocus
                                className="character-name-input"
                              />
                            ) : (
                              <span 
                                onClick={() => {
                                  setEditingCharacterId(container.id);
                                  setEditingCharacterName(worldData.characters?.[`character_${container.id}`]?.name || 'Character Name');
                                }}
                                className="character-name-text"
                              >
                                {worldData.characters?.[`character_${container.id}`]?.name || 'Character Name'}
                              </span>
                            )}
                          </span>
                          <EllipseIcon/>
                        </div>
                        <div className="worldbuilding-characters-fields">
                          <div className="worldbuilding-characters-field pronouns-field">
                            <input 
                              type="text" 
                              placeholder="Pronouns" 
                              className="worldbuilding-field-textarea"
                              value={worldData.characters?.[`character_${container.id}`]?.pronouns || ''}
                              onChange={(e) => handleWorldbuildingChange('characters', `character_${container.id}`, {
                                ...worldData.characters?.[`character_${container.id}`],
                                pronouns: e.target.value
                              })}
                            />
                          </div>
                          <div className="worldbuilding-characters-field character-type-field">
                              <input 
                                type="text" 
                                placeholder="Character Type" 
                                className="worldbuilding-field-textarea"
                                value={worldData.characters?.[`character_${container.id}`]?.characterType || ''}
                                onChange={(e) => handleWorldbuildingChange('characters', `character_${container.id}`, {
                                  ...worldData.characters?.[`character_${container.id}`],
                                  characterType: e.target.value
                                })}
                              />
                          </div>
                          <div className="worldbuilding-characters-field">
                            <span className="worldbuilding-field-title">Personality</span>
                            <textarea 
                              placeholder="Describe your writing style and tone. Consider elements like formality, mood, sentence structure, and word choice." 
                              className="worldbuilding-field-textarea"
                              value={worldData.characters?.[`character_${container.id}`]?.personality || ''}
                              onChange={(e) => {
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('characters', `character_${container.id}`, {
                                  ...worldData.characters?.[`character_${container.id}`],
                                  personality: e.target.value
                                });
                              }}
                            />
                            <footer className="worldbuilding-field-footer">
                              <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                            </footer>
                          </div>
                          <div className="worldbuilding-characters-field">
                            <span className="worldbuilding-field-title">Life Story</span>
                            <textarea 
                              placeholder="Describe your character's life story..." 
                              className="worldbuilding-field-textarea"
                              value={worldData.characters?.[`character_${container.id}`]?.lifeStory || ''}
                              onChange={(e) => {
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('characters', `character_${container.id}`, {
                                  ...worldData.characters?.[`character_${container.id}`],
                                  lifeStory: e.target.value
                                });
                              }}
                            />
                            <footer className="worldbuilding-field-footer">
                              <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                            </footer>
                          </div>
                          <div className="worldbuilding-characters-field">
                            <span className="worldbuilding-field-title">Appearance</span>
                            <textarea 
                              placeholder="Describe your character's appearance..." 
                              className="worldbuilding-field-textarea"
                              value={worldData.characters?.[`character_${container.id}`]?.appearance || ''}
                              onChange={(e) => {
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('characters', `character_${container.id}`, {
                                  ...worldData.characters?.[`character_${container.id}`],
                                  appearance: e.target.value
                                });
                              }}
                            />
                            <footer className="worldbuilding-field-footer">
                              <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                            </footer>
                          </div>
                          
                          <div className="worldbuilding-characters-field">
                            <span className="worldbuilding-field-title">Dialogue Style</span>
                            <textarea 
                              placeholder="Describe your character's dialogue style..." 
                              className="worldbuilding-field-textarea"
                              value={worldData.characters?.[`character_${container.id}`]?.dialogueStyle || ''}
                              onChange={(e) => {
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('characters', `character_${container.id}`, {
                                  ...worldData.characters?.[`character_${container.id}`],
                                  dialogueStyle: e.target.value
                                });
                              }}
                            />
                            <footer className="worldbuilding-field-footer">
                              <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                            </footer>
                          </div>
                        
                        </div>
                        <div className="worldbuilding-characters-footer worldbuilding-general-footer ">
                          <button className="worldbuilding-characters-footer-button worldbuilding-custom-button"> <PlusIcon/><span>Custom</span></button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="worldbuilding-worldforge-content" data-section="worldforge">
                    <div className="worldforge-header worldbuilding-option-header">
                      <span><WorldbuildingIcon/> World</span>
                      <div style={{ position: 'relative' }}>
                        <button className="worldforge-add-button worldbuilding-add-button" onClick={toggleWorldforgeAddMenu}>
                          <PlusIcon/>
                          <span className="worldforge-add-detail">Add Detail</span>
                        </button>
                        <div className="worldforge-add-menu">
                          {worldforgeItems.map((item) => (
                            <button 
                              key={item.id} 
                              className="worldforge-add-menu-item"
                              onClick={() => addWorldforgeContainer(item.title)}
                            >
                              <span className="worldforge-add-menu-item-title">
                                <WorldbuildingIcon />
                                {item.title}
                              </span>
                              <span className="worldforge-add-menu-item-description">
                                {item.description}
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    {worldforgeContainers.map((container) => (
                      <div key={container.id} className="worldforge-body worldbuilding-body">
                        <div className="worldforge-header worldbuilding-header">
                          <span>{container.title}</span>
                          <EllipseIcon/>
                        </div>
                        <div className="worldforge-fields worldbuilding-fields">
                          <div className="worldforge-field worldbuilding-field">
                            <span className="worldbuilding-field-title">Name</span>
                            <textarea 
                              placeholder="Provide a distinctive name..."
                              className="worldbuilding-field-textarea"
                              value={worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`]?.name || ''}
                              onChange={(e) => {
                                console.log('Worldforge input change:', {
                                  title: container.title,
                                  id: container.id,
                                  value: e.target.value
                                });
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('worldforge', `${container.title.toLowerCase()}_${container.id}`, {
                                  ...worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`],
                                  name: e.target.value
                                });
                              }}
                            />
                          </div>
                          <div className="worldforge-field worldbuilding-field">
                            <span className="worldbuilding-field-title">Sensory Description</span>
                            <textarea 
                              placeholder="Describe this element in detail." 
                              className="worldbuilding-field-textarea"
                              value={worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`]?.sensoryDescription || ''}
                              onChange={(e) => {
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('worldforge', `${container.title.toLowerCase()}_${container.id}`, {
                                  ...worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`],
                                  sensoryDescription: e.target.value
                                });
                              }}
                            />
                            <footer className="worldbuilding-field-footer">
                              <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                            </footer>
                          </div>
                          <div className="worldforge-field worldbuilding-field">
                            <span className="worldbuilding-field-title">Location</span>
                            <textarea 
                              placeholder="Describe how this element impacts your world." 
                              className="worldbuilding-field-textarea"
                              value={worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`]?.location || ''}
                              onChange={(e) => {
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('worldforge', `${container.title.toLowerCase()}_${container.id}`, {
                                  ...worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`],
                                  location: e.target.value
                                });
                              }}
                            />
                            <footer className="worldbuilding-field-footer">
                              <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                            </footer>
                          </div>
                          <div className="worldforge-field worldbuilding-field">
                            <span className="worldbuilding-field-title">Unique Features</span>
                            <textarea 
                              placeholder="List notable or unusual aspects..." 
                              className="worldbuilding-field-textarea"
                              value={worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`]?.uniqueFeatures || ''}
                              onChange={(e) => {
                                autoResizeTextarea(e);
                                handleWorldbuildingChange('worldforge', `${container.title.toLowerCase()}_${container.id}`, {
                                  ...worldData.worldforge?.[`${container.title.toLowerCase()}_${container.id}`],
                                  uniqueFeatures: e.target.value
                                });
                              }}
                            />
                            <footer className="worldbuilding-field-footer">
                              <button className="worldbuilding-field-footer-button"><ComposeIcon/>Compose</button>
                            </footer>
                          </div>
                        </div>
                        <div className="worldforge-footer worldbuilding-general-footer">
                          <button className="worldbuilding-custom-button"> <PlusIcon/><span>Custom</span></button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="worldbuilding-outline-content" data-section="outline">
                    <div className="general-details-header worldbuilding-option-header">
                      <div className="general-details-header-title">
                        <span><WorldbuildingIcon/> Outline</span>
                      </div>
                    </div>
                    <div className="worldbuilding-body">
                      <div className="general-details-header worldbuilding-header">
                        <span>Outline</span>
                        <EllipseIcon/>
                      </div>
                      <div className="worldbuilding-fields">
                        <div className="worldbuilding-field">
                          <span className="worldbuilding-field-title">Story Outline</span>
                          <textarea 
                            placeholder="Write or generate your story outline..." 
                            className="worldbuilding-field-textarea"
                            value={(() => {
                              try {
                                // If it's a JSON string, parse it first
                                const outlineContent = worldData.outline?.content;
                                if (typeof outlineContent === 'string' && outlineContent.startsWith('{')) {
                                  const parsed = JSON.parse(outlineContent);
                                  return parsed.outline || '';
                                }
                                // Otherwise return as is
                                return outlineContent || '';
                              } catch (e) {
                                return worldData.outline?.content || '';
                              }
                            })()}
                            onChange={(e) => {
                              autoResizeTextarea(e);
                              handleWorldbuildingChange('outline', 'content', e.target.value);
                            }}
                          />
                          <footer className="worldbuilding-field-footer">
                            <button 
                              className="worldbuilding-field-footer-button"
                              onClick={handleGenerateOutline}
                            >
                              <ComposeIcon/>Generate Outline
                            </button>
                          </footer>
                        </div>
                      </div>
                      <div className="worldbuilding-general-footer">
                        <button className="worldbuilding-custom-button">
                          <PlusIcon/><span>Custom</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


export default LeftSidebar;

