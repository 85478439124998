import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageriftLogo } from './SVGLibrary';
import './HomePage.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const StarryBackground = () => {
  const mountRef = useRef(null);
  const starsRef = useRef([]);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 10000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    const geometry = new THREE.SphereGeometry(1, 64, 64);
    const starArray = [];

    const space = {
      dimensions: { x: window.innerWidth, y: window.innerHeight, z: 500 },
      createStar: function () {
        const colors = [
          { color: 0xffffff, percentage: 50 },
          { color: 0xadd8e6, percentage: 30 },
          { color: 0xffa500, percentage: 20 }
        ];

        const random = Math.random() * 100;
        let cumulativePercentage = 0;
        let selectedColor = colors[0].color;

        for (let i = 0; i < colors.length; i++) {
          cumulativePercentage += colors[i].percentage;
          if (random <= cumulativePercentage) {
            selectedColor = colors[i].color;
            break;
          }
        }

        const material = new THREE.MeshBasicMaterial({ 
          color: selectedColor,
          transparent: true,
          opacity: 0.8
        });

        let obj = new THREE.Mesh(geometry, material);
        
        const spriteMaterial = new THREE.SpriteMaterial({
          map: new THREE.TextureLoader().load('/glow.png'),
          color: selectedColor,
          transparent: true,
          opacity: 0.4,
          blending: THREE.AdditiveBlending
        });
        
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(4, 4, 1);
        obj.add(sprite);
        
        let coordinates = this.getRandCoordinates();
        let velocity = this.getRandMovement();
        for (let vect in coordinates) {
          obj.position[vect] = coordinates[vect];
        }
        obj["movement"] = {};
        for (let vect in velocity) {
          obj.movement[vect] = velocity[vect];
        }
        obj.userData = { 
          scaleDirection: 1,
          originalOpacity: material.opacity,
          sprite: sprite,
          originalSpriteOpacity: sprite.material.opacity
        };
        return obj;
      },
      getRandMovement: function () {
        let vectorV = { x: 0, y: 0, z: 0 };
        for (let vect in vectorV) {
          let randNum = Math.floor(Math.random() - 0.5) / 150;
          if (randNum < 0) {
            randNum -= 0.001;
          } else if (randNum > 0) {
            randNum += 0.001;
          } else if (randNum === 0) {
            randNum = 0.001;
          }
          vectorV[vect] = randNum;
        }
        return vectorV;
      },
      getRandCoordinates: function () {
        let vector = { x: 0, y: 0, z: 0 };
        for (let vect in this.dimensions) {
          vector[vect] = (Math.random() - 0.5) * this.dimensions[vect];
        }
        return vector;
      },
    };

    const starCount = 400;
    for (let i = 0; i < starCount; i++) {
      const star = space.createStar();
      starArray.push(star);
      scene.add(star);
    }

    camera.position.z = 900;

    const basePulseSpeed = 0.015;
    
    const handleTextHover = (isHovering) => {
      const starsToFlare = starArray
        .sort(() => Math.random() - 0.5)
        .slice(0, Math.floor(starArray.length * 0.2));

      if (isHovering) {
        starsToFlare.forEach((star, index) => {
          setTimeout(() => {
            // Create light streaks
            const streakCount = 4;
            const streakLength = 15;
            
            // Add randomized delays for each streak
            for (let i = 0; i < streakCount; i++) {
              setTimeout(() => {
                const streakGeometry = new THREE.PlaneGeometry(0.8, streakLength);
                const streakMaterial = new THREE.MeshBasicMaterial({
                  color: star.material.color,
                  transparent: true,
                  opacity: 0.6,
                  blending: THREE.AdditiveBlending
                });
                
                const streak = new THREE.Mesh(streakGeometry, streakMaterial);
                streak.position.copy(star.position);
                streak.rotation.z = (Math.PI * i) / (streakCount / 2);
                scene.add(streak);

                const longStreakGeometry = new THREE.PlaneGeometry(0.4, streakLength * 1.5);
                const longStreakMaterial = new THREE.MeshBasicMaterial({
                  color: star.material.color,
                  transparent: true,
                  opacity: 0.3,
                  blending: THREE.AdditiveBlending
                });
                
                const longStreak = new THREE.Mesh(longStreakGeometry, longStreakMaterial);
                longStreak.position.copy(star.position);
                longStreak.rotation.z = (Math.PI * i) / (streakCount / 2);
                scene.add(longStreak);

                const startTime = Date.now();
                
                function animateStreak() {
                  const elapsed = Date.now() - startTime;
                  const progress = Math.min(elapsed / 1200, 1); // Increased duration
                  
                  if (progress < 1) {
                    // Smoother easing function
                    const easeInOut = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                    const fadeProgress = progress < 0.3 ? easeInOut(progress / 0.3) : easeInOut((1 - progress) / 0.7);
                    
                    streak.material.opacity = 0.6 * fadeProgress;
                    longStreak.material.opacity = 0.3 * fadeProgress;
                    requestAnimationFrame(animateStreak);
                  } else {
                    scene.remove(streak);
                    scene.remove(longStreak);
                    streak.geometry.dispose();
                    streak.material.dispose();
                    longStreak.geometry.dispose();
                    longStreak.material.dispose();
                  }
                }
                
                animateStreak();
              }, Math.random() * 300); // Random delay for each streak
            }

            // Increased star flare intensity with smoother animation
            const starStartTime = Date.now();
            const starAnimDuration = 1200;
            
            function animateStarFlare() {
              const elapsed = Date.now() - starStartTime;
              const progress = Math.min(elapsed / starAnimDuration, 1);
              
              if (progress < 1) {
                const easeInOut = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                const intensity = progress < 0.3 ? easeInOut(progress / 0.3) : easeInOut((1 - progress) / 0.7);
                
                star.material.opacity = star.userData.originalOpacity * (1 + (2.5 * intensity));
                star.userData.sprite.material.opacity = star.userData.originalSpriteOpacity * (1 + (5 * intensity));
                const scale = 1 + (1.2 * intensity);
                star.scale.set(scale, scale, scale);
                
                requestAnimationFrame(animateStarFlare);
              }
            }
            
            animateStarFlare();

          }, index * 100 + Math.random() * 200); // Increased and randomized delay between stars
        });
      }
    };

    const textElement = document.querySelector('.home-main h1 span');
    if (textElement) {
      textElement.addEventListener('mouseenter', () => handleTextHover(true));
      textElement.addEventListener('mouseleave', () => handleTextHover(false));
    }

    const animate = () => {
      requestAnimationFrame(animate);
      for (let i = 0; i < starArray.length; i++) {
        let star = starArray[i];
        for (let vect in star.movement) {
          star.position[vect] += star.movement[vect];
        }
        
        const randomFactor = 1 + (Math.random() - 0.5) * 0.5;
        const currentPulseSpeed = basePulseSpeed * randomFactor;
        
        const newScale = star.scale.x + currentPulseSpeed * star.userData.scaleDirection;
        star.scale.set(newScale, newScale, newScale);
        
        if (star.scale.x > 1.4 || star.scale.x < 0.6) {
          star.userData.scaleDirection *= -1;
        }
      }
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      const textElement = document.querySelector('.home-main h1 span');
      if (textElement) {
        textElement.removeEventListener('mouseenter', () => handleTextHover(true));
        textElement.removeEventListener('mouseleave', () => handleTextHover(false));
      }
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} className="starry-background" style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }} />;
};

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="home-page">
      <StarryBackground />
      <nav className="home-nav">
        <div className="logo">
          <PageriftLogo />
        </div>

      </nav>

      <main className="home-main">
        <img src="/Hero1.png" alt="Hero background" className="hero-image-1"/>
        <img src="/Hero4.png" alt="Hero background" className="hero-image-2"/>
        <div className="content-wrapper">
           <h1>Write Brilliantly. 
            <br/>
          <span>We'll Light</span> The Way.</h1>
           <p>Craft your stories with confidence, backed by an intelligent AI writing partner.</p>
          <button onClick={() => navigate('/login')} className="cta-button">
            <span>Join the waitlist</span>
          </button>
        </div>
      </main>
      <div className="subhero-teaser">
        <img src="/screen_frame.png" alt="Screen frame" />
      </div>  
      <div className="subhero-gradient">

      </div>
      <div className="subhero-gradient-2"></div>
    </div>
  );
};

export default HomePage;
