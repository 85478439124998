import React, { useEffect, useState, useContext } from 'react';
import './Modal.css';
import { useNavigate } from 'react-router-dom';
import { ProfileIcon, AccountIcon, DownArrowIcon } from './SVGLibrary';
import { useTheme } from '../ThemeContext';

const Modal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState('Profile');
  const [showWordCount, setShowWordCount] = useState(true);
  const navigate = useNavigate();
  const { currentTheme, updateTheme } = useTheme();
  const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);

  useEffect(() => {
    // Disable scrolling on the body when the modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleWordCountToggle = (e) => {
    setShowWordCount(e.target.checked);
    localStorage.setItem('showWordCount', e.target.checked);
    // Dispatch custom event for HorizontalHeader to listen to
    window.dispatchEvent(new CustomEvent('wordCountToggle', { 
      detail: { show: e.target.checked } 
    }));
  };

  const handleThemeChange = (theme) => {
    updateTheme(theme);
    setIsThemeDropdownOpen(false);
  };

  return (
    <div className="modal-overlay" onClick={handleBackdropClick}>
      <div className="modal-content">
        <div className="modal-sidebar">
          {/* My Account Section */}
          <div className="sidebar-section">
            <h3 className='sidebar-section-title'><AccountIcon /><span>My account</span></h3>
            <div className="account-details">
              <div className="account-details-email">
                <span className='user-icon'>
                  <ProfileIcon />
                </span>
                <span className='user-email'>placeholder@gmail.com</span>
              </div>
            </div>
            <ul>
              <li
                className={`modal-option ${selectedOption === 'Profile' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Profile')}
              >
                Profile
              </li>
              <li
                className={`modal-option ${selectedOption === 'Preferences' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Preferences')}
              >
                Preferences
              </li>
              <li
                className={`modal-option ${selectedOption === 'Billing' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Billing')}
              >
                Billing
              </li>
              <li 
                className={`modal-option ${selectedOption === 'Security' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Security')}
              >
                Security & Access
              </li>
            </ul>
          </div>

          {/* Pagerift app Section */}
          <div className="sidebar-section">
            <h3 className='sidebar-section-title'><AccountIcon /><span>Pagerift app</span></h3>
            <ul>
              <li className={`modal-option ${selectedOption === 'Your plan' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Your plan')}
              >
                Your plan
              </li>
              <li className={`modal-option ${selectedOption === 'Settings' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Settings')}
              >
                Settings
              </li>
              <li className={`modal-option ${selectedOption === 'Docs' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Docs')}
              >
                Docs
              </li>
              <li className={`modal-option ${selectedOption === 'Tutorials' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Tutorials')}
              >
                Tutorials
              </li>
              <li className={`modal-option ${selectedOption === 'Import' ? 'active' : ''}`}
                onClick={() => handleOptionClick('Import')}
              >
                Import
              </li>
            </ul>
          </div>
        </div>
        <div className="modal-main-content">
          <button className="modal-close-button" onClick={onClose}>Close</button>
          {selectedOption === 'Profile' && (
            <>
              
              <div className='profile-content'>
                <div className='profile-my-profile-section'>
                  <div className='modal-profile-header modal-profile-header-profile'>
                    <span className='modal-profile-header-title'>My Profile</span>
                    <span className='modal-profile-header-subtitle'>Manage your Pagerift profile</span>
                  </div>
                  <div className='modal-profile-section'>
                    <div className='modal-profile-icon'>
                      <span><ProfileIcon /></span>
                    </div>
                    <div className='modal-profile-info'>
                      <div className='profile-info-title'>
                        <span>Preferred Name</span>
                      </div>
                      <input type='text' placeholder='Enter your preferred name' />
                    </div>
                  </div>
                </div>

                <div className='profile-account-section'>
                  <div className='modal-profile-header'>
                    <span className='modal-profile-header-title'>Account</span>
                    <span className='modal-profile-header-subtitle'>Manage your account</span>
                  </div>
                  <div className='profile-account-section-content'>
                    <div className='account-section-item account-section-item-email'>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Email</span>
                        <span className='account-section-item-info-subtitle'>placeholder@gmail.com</span>
                      </div>
                      <div className='account-section-item-actions'>
                        <button className='account-section-item-action-button'>
                          <span>Change email</span>
                        </button>
                      </div>
                    </div>
                    <div className='account-section-item account-section-item-password'>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Password</span>
                        <span className='account-section-item-info-subtitle'>Change the password of your account</span>
                      </div>
                      <div className='account-section-item-actions'>
                        <button className='account-section-item-action-button'>
                          <span>Change password</span>
                        </button>
                      </div>
                    </div>
                    <div className='account-section-item account-section-item-authentication  '>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Multi-factor authentication</span>
                        <span className='account-section-item-info-subtitle'>Manage your authentication methods</span>
                      </div>
                      <div className='account-section-item-actions'>
                        <button className='account-section-item-action-button'>
                          <span>Enable</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='profile-support-section'>
                  <div className='modal-profile-header'>
                    <span className='modal-profile-header-title'>Support</span>
                    <span className='modal-profile-header-subtitle'>Pagerift account support</span>
                  </div>
                  <div className='profile-support-section-content'>
                    <div className='profile-support-section-item'>
                      <div className='profile-support-section-item-info'>
                        <span className='profile-support-section-item-info-title'>Contact us</span>
                        <span className='profile-support-section-item-info-subtitle'>Contact us at support@pagerift.com</span>
                      </div>
                      <div className='profile-support-section-item-actions'>
                        <button className='profile-support-section-item-action-button'>
                          <span>Contact us</span>
                        </button>
                      </div>
                    </div>
                    <div className='profile-support-section-item'>
                      <div className='profile-support-section-item-info'>
                        <span className='profile-support-section-item-info-title account-delete-title'>Delete my account</span>
                        <span className='profile-support-section-item-info-subtitle'>Permanently delete your account</span>
                      </div>
                      <div className='profile-support-section-item-actions'>
                        <button className='profile-support-section-item-action-button'>
                          <span>Delete account</span>
                        </button> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedOption === 'Preferences' && (
            <>
              <div className='preferences-content'>
                <div className='preferences-header'>
                  <span className='modal-profile-header-title'>Preferences</span>
                  <span className='modal-profile-header-subtitle'>Manage your preferences</span>
                </div>
                
                <div className='preferences-editor-section'>
                  <div className='modal-profile-header'>
                    <span className='modal-profile-header-title'>Editor</span>
                    <span className='modal-profile-header-subtitle'>Select your interface color scheme</span>
                  </div>
                  <div className='preferences-editor-section-content'>
                    <div className='account-section-item'>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Font</span>
                        <span className='account-section-item-info-subtitle'>Choose your preferred font</span>
                      </div>
                      <div className='account-section-item-actions preferences-actions'>
                        <button className='account-section-item-action-button'>
                          <span>Inter</span>
                          <DownArrowIcon />
                        </button>
                      </div>
                    </div>

                    <div className='account-section-item'>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Interface theme</span>
                        <span className='account-section-item-info-subtitle'>Select your theme</span>
                      </div>
                      <div className='account-section-item-actions preferences-actions'>
                        <button 
                          className='account-section-item-action-button theme-preference-button'
                          onClick={() => setIsThemeDropdownOpen(!isThemeDropdownOpen)}
                        >
                          <span>{currentTheme}</span>
                          <DownArrowIcon />
                        </button>
                        {isThemeDropdownOpen && (
                          <div className='option-dropdown'>
                            {['light', 'umbra', 'chloros', 'rosequantum'].map((themeName) => (
                              <button key={themeName} onClick={() => handleThemeChange(themeName)}>
                                {themeName.charAt(0).toUpperCase() + themeName.slice(1)}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='account-section-item'>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Show word count</span>
                        <span className='account-section-item-info-subtitle'>Display word count in editor</span>
                      </div>
                      <div className='account-section-item-actions preferences-actions'>
                        <label className="toggle-switch">
                          <input 
                            className='word-count-checkbox' 
                            type="checkbox" 
                            defaultChecked={showWordCount}
                            onChange={handleWordCountToggle} 
                          />
                          <span className="toggle-slider"></span>
                        </label>
                      </div>
                    </div>

                    <div className='account-section-item'>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Placeholder 1</span>
                        <span className='account-section-item-info-subtitle'>Placeholder description</span>
                      </div>
                      <div className='account-section-item-actions preferences-actions'>
                        <button className='account-section-item-action-button'>
                          <span>Action</span>
                          <DownArrowIcon />
                        </button>
                      </div>
                    </div>

                    <div className='account-section-item'>
                      <div className='account-section-item-info'>
                        <span className='account-section-item-info-title'>Placeholder 2</span>
                        <span className='account-section-item-info-subtitle'>Placeholder description</span>
                      </div>
                      <div className='account-section-item-actions preferences-actions'>
                        <button className='account-section-item-action-button'>
                          <span>Action</span>
                          <DownArrowIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedOption === 'Billing' && (
            <>
              <div className='billing-content'>
                <div className='billing-header'>
                  <span className='modal-profile-header-title'>Billing</span>
                  <span className='modal-profile-header-subtitle'>Manage your billing</span>
                </div>

                <div className='billing-section'>
                  <div className='account-section-item'>
                    <div className='account-section-item-info'>
                      <span className='account-section-item-info-title '>Current Plan</span>
                      <span className='account-section-item-info-subtitle'>Writer</span>
                    </div>
                    <div className='account-section-item-actions'>
                      <button className='account-section-item-action-button'>
                        <span>Upgrade Plan</span>
                      </button>
                    </div>
                  </div>

                  <div className='account-section-item'>
                    <div className='account-section-item-info'>
                      <span className='account-section-item-info-title'>Billing</span>
                      <span className='account-section-item-info-subtitle'>Placeholder</span>
                    </div>
                    <div className='account-section-item-actions'>
                      <button className='account-section-item-action-button'>
                        <span>Update Billing Info</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedOption === 'Security' && (
            <>
              <h2>Security & Access</h2>
              {/* Security & Access content */}
            </>
          )}
          {selectedOption === 'Your plan' && (
            <>
              <h2>Your plan</h2>
              {/* Your plan content */}
            </>
          )}
          {selectedOption === 'Settings' && (
            <>
              <h2>Settings</h2>
              {/* Settings content */}
            </>
          )}
          {selectedOption === 'Docs' && (
            <>
              <h2>Docs</h2>
              {/* Docs content */}
            </>
          )}
          {selectedOption === 'Tutorials' && (
            <>
              <h2>Tutorials</h2>
              {/* Tutorials content */}
            </>
          )}
          {selectedOption === 'Import' && (
            <>
              <h2>Import</h2>
              {/* Import content */}
            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default Modal;