import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import CharacterCount from '@tiptap/extension-character-count';
import FloatingToolbar from './FloatingToolbar';
import './EditorContent.css';
import { RephraseIcon, SimplifyIcon, DistinctiveIcon, ForeshadowIcon, EnhanceDialogueIcon, StrengthenIcon, HeightenEmotionIcon, VividnessIcon, SageBubble, LengthIcon, ExpandIcon, RewriteIconLight, ChangeToneIcon, HappyToneIcon, SadToneIcon, AngryToneIcon, ScaredToneIcon, TenderToneIcon, ExcitedToneIcon, ShorterIcon, LongerIcon, SageChatIcon } from './SVGLibrary.js';
import { bubbleMenuRewrite } from '../services/bubbleMenuService';
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import SageChat from './SageChat';
import { Extension } from '@tiptap/core';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Plugin } from 'prosemirror-state';

const useSelectionPosition = (editor) => {
    const [position, setPosition] = useState(null);

    const updatePosition = useCallback(() => {
        if (!editor || editor.state.selection.empty) {
            setPosition(null);
            return;
        }

        const { from, to } = editor.state.selection;
        const start = editor.view.coordsAtPos(from);
        const end = editor.view.coordsAtPos(to);
        const editorContainer = document.querySelector('.editor-container');
        const editorRect = editorContainer.getBoundingClientRect();
        
        // Calculate center of selection
        const selectionCenter = (start.left + end.left) / 2;
        
        // Calculate maximum allowed positions
        const CHAT_WIDTH = 740; // max-width of sagechat
        const MARGIN = 16; // minimum margin from edges
        
        let left = selectionCenter - editorRect.left;
        
        // Adjust horizontal position if it would cause overflow
        const maxLeft = editorRect.width - MARGIN;
        const minLeft = MARGIN;
        
        // If chat would overflow right
        if (left + (CHAT_WIDTH/2) > maxLeft) {
            left = maxLeft - CHAT_WIDTH/2;
        }
        
        // If chat would overflow left
        if (left - (CHAT_WIDTH/2) < minLeft) {
            left = minLeft + CHAT_WIDTH/2;
        }
        
        setPosition({
            left,
            top: end.bottom - editorRect.top // Changed from start.top to end.bottom
        });
    }, [editor]);

    useEffect(() => {
        if (editor) {
            editor.on('selectionUpdate', updatePosition);
            return () => editor.off('selectionUpdate', updatePosition);
        }
    }, [editor, updatePosition]);

    return position;
};

// Add this before the TiptapEditor component
const createSelectionDecorationPlugin = () => {
  return new Plugin({
    state: {
      init() { 
        return DecorationSet.empty;
      },
      apply(tr, oldSet) {
        // Clear decorations if editor loses focus
        if (!tr.docChanged && tr.getMeta('focus') === false) {
          return DecorationSet.empty;
        }
        
        // Get the current selection
        const selection = tr.selection;
        
        // If there's no selection or it's empty, clear decorations
        if (!selection || selection.empty) {
          return DecorationSet.empty;
        }
        
        // Create new decoration for the current selection
        return DecorationSet.create(tr.doc, [
          Decoration.inline(selection.from, selection.to, {
            class: 'sage-selection'
          })
        ]);
      }
    },
    props: {
      decorations(state) { 
        return this.getState(state);
      },
      handleDOMEvents: {
        blur(view) {
          // Dispatch transaction to clear decorations on blur
          view.dispatch(view.state.tr.setMeta('focus', false));
          return false;
        }
      }
    }
  });
};

const TiptapEditor = forwardRef(({
  editorState,
  onEditorChange,
  title,
  onTitleChange,
  onRewrite,
  setRewriteOptions,
  setOriginalText,
  setOperationType,
  setCharacterCount,
  projectId
}, ref) => {
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isSageChatVisible, setIsSageChatVisible] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [selectionRange, setSelectionRange] = useState(null);

    const editor = useEditor({
        extensions: [
            StarterKit,
            Heading.configure({
                levels: [1, 2, 3],
            }),
            Document,
            Paragraph,
            Text,
            Bold,
            Italic,
            Underline,
            Highlight,
            CharacterCount,
            Placeholder.configure({
                placeholder: 'Write your story here...',
            }),
            Extension.create({
                addProseMirrorPlugins() {
                    return [createSelectionDecorationPlugin()];
                },
            }),
        ],
        content: editorState,
        onUpdate: ({ editor }) => {
            onEditorChange(editor.getHTML());
            setCharacterCount(editor.state.doc.textContent.length);
        },
    });

    // Then declare selectionPosition
    const selectionPosition = useSelectionPosition(editor);

    const shouldShowBubbleMenu = useCallback(() => {
        if (!editor) return false;
        if (isSageChatVisible) return false;
        // Add a small delay to prevent the bubble menu from showing during SageChat close
        if (editor.state.selection.empty) return false;
        return true;
    }, [editor, isSageChatVisible]);

    const closeBubbleMenu = useCallback(() => {
        if (editor) {
            const { to } = editor.state.selection;
            editor.commands.setTextSelection({ from: to, to: to });
            editor.commands.blur();
            setActiveDropdown(null);
        }
    }, [editor]);

    const handleBubbleMenuRewrite = async (option) => {
        if (!editor) return;
        
        const { from, to } = editor.state.selection;
        const selectedText = editor.state.doc.textBetween(from, to);
        
        closeBubbleMenu();

        try {
            const rewrittenTexts = await bubbleMenuRewrite(selectedText, option, projectId);
            
            setOriginalText(selectedText);
            setRewriteOptions(rewrittenTexts);
            setOperationType(option);
        } catch (error) {
            console.error('Error rewriting text:', error);
        }
    };

    const handleToneChange = async (tone) => {
        if (!editor) return;
        
        const { from, to } = editor.state.selection;
        const selectedText = editor.state.doc.textBetween(from, to);
        
        closeBubbleMenu();

        try {
            const changedToneTexts = await bubbleMenuRewrite(selectedText, tone, projectId);
            
            setOriginalText(selectedText);
            setRewriteOptions(changedToneTexts);
            setOperationType(tone);
        } catch (error) {
            console.error('Error changing tone:', error);
        }
    };

    const handleLengthChange = (lengthOption) => {
        if (!editor) return;
        
        const { from, to } = editor.state.selection;
        const selectedText = editor.state.doc.textBetween(from, to);
        
        closeBubbleMenu();

        // Here you would typically call a service to change the length
        // For now, we'll just log the action
        console.log(`Change length to ${lengthOption} for text: ${selectedText}`);
    };

    useEffect(() => {
        if (editor) {
            const handleSelectionChange = () => {
                if (editor.state.selection.empty) {
                    setActiveDropdown(null);
                }
            };

            editor.on('selectionUpdate', handleSelectionChange);

            return () => {
                editor.off('selectionUpdate', handleSelectionChange);
            };
        }
    }, [editor]);

    // Move this block up (around line 270, before handleSageChatToggle)
    const handleSelectionChange = useCallback(() => {
        if (editor) {
            const selection = editor.state.selection;
            if (!selection.empty) {  // Only update if there's an actual selection
                const text = editor.state.doc.textBetween(
                    selection.from,
                    selection.to
                );
                setSelectedText(text);
                setSelectionRange({ from: selection.from, to: selection.to });
            }
        }
    }, [editor]);

    // Then keep handleSageChatToggle where it is
    const handleSageChatToggle = useCallback(() => {
        if (!isSageChatVisible) {
            handleSelectionChange();
            setActiveDropdown(null);
        } else {
            setActiveDropdown(null);
        }
        setIsSageChatVisible(!isSageChatVisible);
    }, [isSageChatVisible, handleSelectionChange]);

    // Add handler for text replacement
    const handleReplaceText = useCallback((newText) => {
        if (editor && selectionRange) {
            editor.chain()
                .focus()
                .deleteRange(selectionRange)
                .insertContent(newText)
                .run();
        }
    }, [editor, selectionRange]);

    useEffect(() => {
        if (editor) {
            editor.on('selectionUpdate', handleSelectionChange);
            return () => editor.off('selectionUpdate', handleSelectionChange);
        }
    }, [editor, handleSelectionChange]);

    useEffect(() => {
      const handleResize = () => {
        if (editor) {
          handleSelectionChange();
        }
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [editor, handleSelectionChange]);

    useEffect(() => {
      const handleScroll = () => {
        if (editor && isSageChatVisible) {
          handleSelectionChange();
        }
      };

      const editorElement = document.querySelector('.editor-container');
      if (editorElement) {
        editorElement.addEventListener('scroll', handleScroll);
        return () => editorElement.removeEventListener('scroll', handleScroll);
      }
    }, [editor, isSageChatVisible, handleSelectionChange]);

    const handleSageChatClose = useCallback(() => {
        if (editor) {
            // Clear selection first
            const { to } = editor.state.selection;
            editor.commands.setTextSelection({ from: to, to: to });
            editor.commands.blur();
        }
        // Then close SageChat
        setIsSageChatVisible(false);
    }, [editor]);

    // Add a new effect to maintain selection when SageChat is visible
    useEffect(() => {
        if (editor && isSageChatVisible && selectionRange) {
            editor.commands.setTextSelection(selectionRange);
        }
    }, [editor, isSageChatVisible, selectionRange]);

    return (
        <div className={`editor-container ${isSageChatVisible ? 'sagechat-active' : ''}`} style={{overflow: 'hidden'}}>
            <div className="overlay">
                
            </div>
            <div className="editor-content">
                <input 
                    className="editor-title" 
                    type="text" 
                    placeholder="Write your title here..." 
                    value={title}
                    onChange={onTitleChange}
                />
                {editor && (
                    <>
                        <BubbleMenu 
                            editor={editor} 
                            tippyOptions={{ 
                                duration: 100,
                                maxWidth: 'fit-content',
                            }}
                            shouldShow={shouldShowBubbleMenu}
                        >
                            <div className="bubble-menu">
                                <div className="bubble-menu-item">
                                    <button className="bubble-menu-button expand-button" onClick={() => setActiveDropdown('expand')}>
                                        <ExpandIcon /> <span className="bubble-menu-button-text">Expand</span>
                                    </button>
                                    {activeDropdown === 'expand' && (
                                        <div className="dropdown-menu">
                                            <button>Option 1</button>
                                            <button>Option 2</button>
                                            <button>Option 3</button>
                                        </div>
                                    )}
                                </div>

                                <div className="bubble-menu-item">
                                    <button className='bubble-menu-button length-button' onClick={() => setActiveDropdown('length')}>
                                         <LengthIcon /> <span className="bubble-menu-button-text">Length</span>
                                    </button>
                                    {activeDropdown === 'length' && (
                                        <div className="dropdown-menu">
                                            <button onClick={() => handleLengthChange('shorter')}> <span className="bubble-menu-button-text">Shorter</span></button>
                                            <button onClick={() => handleLengthChange('longer')}><span className="bubble-menu-button-text">Longer</span></button>
                                        </div>
                                    )}
                                </div>
                                <div className="bubble-menu-item">
                                    <button className="bubble-menu-button rewrite-button" onClick={() => setActiveDropdown('rewrite')}>
                                        <RewriteIconLight /> <span className="bubble-menu-button-text">Rewrite</span>
                                    </button>
                                    {activeDropdown === 'rewrite' && (
                                        <div className="dropdown-menu">
                                            <button onClick={() => handleBubbleMenuRewrite('rephrase')}><RephraseIcon /><span className="bubble-menu-button-text">Rephrase</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('simplify')}><SimplifyIcon /><span className="bubble-menu-button-text">Shorten</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('distinctive')}><DistinctiveIcon /><span className="bubble-menu-button-text">Distinctive</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('foreshadow')}><EnhanceDialogueIcon /><span className="bubble-menu-button-text">Foreshadow</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('enhanceDialogue')}><ForeshadowIcon /><span className="bubble-menu-button-text">Enhance Dialogue</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('strengthenVoice')}><StrengthenIcon /><span className="bubble-menu-button-text">Strengthen Voice</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('heightenEmotion')}><HeightenEmotionIcon /><span className="bubble-menu-button-text">Heighten emotion</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('vividness')}><VividnessIcon /><span className="bubble-menu-button-text">Increase vividness</span></button>
                                        </div>
                                    )}
                                </div>

                                <div className="bubble-menu-item">
                                    <button className='bubble-menu-button change-tone-button' onClick={() => setActiveDropdown('changeTone')}>
                                        <ChangeToneIcon /> <span className="bubble-menu-button-text">Change Tone</span>
                                    </button>
                                    {activeDropdown === 'changeTone' && (
                                        <div className="dropdown-menu">
                                            <button onClick={() => handleToneChange('happy')}><HappyToneIcon /> <span className="bubble-menu-button-text">Happy</span></button>
                                            <button onClick={() => handleToneChange('sad')}><SadToneIcon /> <span className="bubble-menu-button-text">Sad</span></button>
                                            <button onClick={() => handleToneChange('angry')}><AngryToneIcon /> <span className="bubble-menu-button-text">Angry</span></button>
                                            <button onClick={() => handleToneChange('scared')}><ScaredToneIcon /> <span className="bubble-menu-button-text">Scared</span></button>
                                            <button onClick={() => handleToneChange('tender')}><TenderToneIcon /> <span className="bubble-menu-button-text">Tender</span></button>
                                            <button onClick={() => handleToneChange('excited')}><ExcitedToneIcon /> <span className="bubble-menu-button-text">Excited</span></button>
                                            
                                        </div>
                                    )}
                                </div>

                                <div className="bubble-menu-item">
                                   
                                </div>

                            </div>
                        </BubbleMenu>
                        <FloatingToolbar 
                            onRewrite={onRewrite} 
                            editor={editor} 
                            onSageChatToggle={handleSageChatToggle}
                        />
                        <EditorContent editor={editor} />
                        {isSageChatVisible && (
                            <SageChat
                                selectedText={selectedText}
                                projectId={projectId}
                                onReplaceText={handleReplaceText}
                                isVisible={isSageChatVisible}
                                position={selectionPosition}
                                onClose={handleSageChatClose}
                                editor={editor}
                                offsetFromBottom={120}
                            />
                        )}
                        <button 
                            className="open-sagechat-button" 
                            onClick={() => setIsSageChatVisible(!isSageChatVisible)}
                        >
                            <SageBubble />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
});

export default TiptapEditor;
