import React, { useState, useEffect } from 'react';
import {EllipseIcon, InsertIcon, CopyIcon, ThumbsUpIcon, ThumbsDownIcon, HeartIcon, RemoveIcon} from './SVGLibrary';

const RewriteCard = ({ originalText, rewrittenText, onInsert, onBookmark, onRemove, operationType, isBookmarked = false }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (!rewrittenText) {
      setDisplayedText('Waiting for rewrite...');
      setIsComplete(false);
      return;
    }

    if (isBookmarked) {
      setDisplayedText(rewrittenText);
      setIsComplete(true);
      return;
    }

    setIsComplete(false);
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex < rewrittenText.length) {
        setDisplayedText(prevText => prevText + rewrittenText[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(intervalId);
        setIsComplete(true);
      }
    }, 10);

    return () => clearInterval(intervalId);
  }, [rewrittenText, isBookmarked]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(rewrittenText);
  };

  const highlightDifferences = (original, rewritten) => {
    const words1 = original.toLowerCase().split(/\s+/);
    const words2 = rewritten.split(/\s+/);
    const result = [];

    let i = 0, j = 0;
    while (i < words2.length) {
      if (j >= words1.length || words2[i].toLowerCase() !== words1[j]) {
        result.push(
          <span
            key={i}
            className="highlighted-word"
            title={j < words1.length ? `Original: ${words1[j]}` : 'New word'}
          >
            {words2[i]}
          </span>
        );
        i++;
      } else {
        result.push(<span key={i}>{words2[i]}</span>);
        i++;
        j++;
      }
      if (i < words2.length) {
        result.push(' ');
      }
    }

    return result;
  };

  const handleBookmark = () => {
    onBookmark(rewrittenText);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={`rewrite-card`}>
      <div className='card-header-wrapper'>
        <div className='card-header'>
          <p>{capitalizeFirstLetter(operationType || 'rephrase')}</p>
          <button className='card-header-options' onClick={toggleExpand}>
            <EllipseIcon />
          </button>
        </div>
      </div>

      <div className='card-content'>
        <div className="card-text">
          <p>
            {isComplete ? 
              (isExpanded ? 
                rewrittenText : 
                highlightDifferences(originalText, rewrittenText)
              ) : 
              displayedText
            }
          </p>
        </div>
        {(isComplete || isBookmarked) && 
          <div className='card-buttons'>
            <div className='card-action'>
              <button 
                onClick={() => typeof onInsert === 'function' && onInsert(rewrittenText)} 
                className="insert-button">
                <InsertIcon />
              </button>
              <button onClick={handleCopy} className="copy-button">
                <CopyIcon />
              </button>
              <button className='feedback-button thumbs-up'>
                <ThumbsUpIcon />
              </button>
              <button className='feedback-button thumbs-down'>
                <ThumbsDownIcon />
              </button>
            </div>
            {!isBookmarked && (
              <div className='card-feedback'>
                <button 
                  className='feedback-button bookmark'
                  onClick={() => onBookmark(rewrittenText)}
                >
                  <HeartIcon />
                </button>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default RewriteCard;