import React from 'react';
import './CharacterCount.css';

const CharacterCount = ({ count, isSaving, style }) => {
    return (
        <div className="character-count-wrapper">
            {style?.display !== 'none' && (
                <div className="character-count">
                    Characters: {count} 
                </div>
            )}
            <div className="save-state">
                <div className={`save-indicator ${isSaving ? 'saving' : 'saved'}`}></div>
                <span>{isSaving ? "Saving..." : "Saved"}</span>
            </div>
        </div>
    );
};

export default CharacterCount;