import React, { useState, useEffect, useRef } from 'react';
import './FloatingToolbar.css';
import { CloseXIcon, RightArrowIcon, WritePopupIcon, DownArrow, UndoIcon, RedoIcon, BoldIcon, ItalicIcon, UnderlineIcon, WriteIcon, ArrowUpIcon } from './SVGLibrary';

const FloatingToolbar = ({ onRewrite, editor, onSageChatToggle }) => {
  const toggleBold = () => {
    editor.chain().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().toggleItalic().run();
  };

  const toggleUnderline = () => {
    editor.chain().toggleUnderline().run();
  };

  const [isWritePopupOpen, setIsWritePopupOpen] = useState(false);
  const [isModelsPopupOpen, setIsModelsPopupOpen] = useState(false);
  const [isStylePopupOpen, setIsStylePopupOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const toggleWritePopup = () => {
    setIsWritePopupOpen(!isWritePopupOpen);
    setIsModelsPopupOpen(false);
    setIsStylePopupOpen(false);
  };

  const toggleModelsPopup = () => {
    setIsModelsPopupOpen(!isModelsPopupOpen);
    setIsWritePopupOpen(false);
  };

  const toggleStylePopup = () => {
    setIsStylePopupOpen(!isStylePopupOpen);
    setIsModelsPopupOpen(false);
    setIsWritePopupOpen(false);
  };

  const writeButtonRef = useRef(null);
  const writePopupRef = useRef(null);
  const stylePopupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      writePopupRef.current &&
      !writePopupRef.current.contains(event.target) &&
      writeButtonRef.current &&
      !writeButtonRef.current.contains(event.target) &&
      (!stylePopupRef.current || !stylePopupRef.current.contains(event.target))
    ) {
      setIsWritePopupOpen(false);
      setIsModelsPopupOpen(false);
      setIsStylePopupOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleModelSelection = (modelName) => {
    setIsModelsPopupOpen(false);
    setConfirmationMessage(`Model changed to ${modelName}`);
    setTimeout(() => setConfirmationMessage(''), 3000);
  };

  return (
    <div className="floating-toolbar-container">
      <div className="floating-toolbar">
        {confirmationMessage && (
          <div className="confirmation-message-container">
            <div className="confirmation-message-overlay"></div>
            <div className="confirmation-message">{confirmationMessage}</div>
          </div>
        )}
        <div className="toolbar-group toolbar-group-left">
          <div className='toolbar-options'>
            <button>
              <span>Paragraph</span>
              <DownArrow/>
            </button>
          </div>
          <div className="toolbar-divider"></div>
          <div className='toolbar-undo-redo'>
            <button onClick={() => editor.chain().focus().undo().run()}>
              <UndoIcon />
            </button>
            <button onClick={() => editor.chain().focus().redo().run()}>
              <RedoIcon />
            </button>
          </div>
          <div className="toolbar-formatting">
            <button className='bold-button' onClick={toggleBold}>
              <BoldIcon />
            </button>
            <button className='italic-button' onClick={toggleItalic}>
              <ItalicIcon />
            </button>
            <button className='underline-button' onClick={toggleUnderline}>
              <UnderlineIcon />
            </button>
          </div>
          <div className='toolbar-divider'></div>
          <div className="toolbar-group-right">
            <div className='toolbar-write-button' onClick={toggleWritePopup} ref={writeButtonRef}>
              <WriteIcon />
              <button>Write</button>
              <ArrowUpIcon />
            </div>
            {isWritePopupOpen && (
              <div className="write-popup-menu" ref={writePopupRef}>
                <button>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>Auto Complete</span>
                    <small>Placeholder subtitle</small>
                  </div>
                </button>
                <button>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>Instructed</span>
                    <small>Placeholder subtitle</small>
                  </div>
                </button>
                <button onClick={toggleStylePopup}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>Style</span>
                    <small>Placeholder subtitle</small>
                  </div>
                  <RightArrowIcon />
                </button>
                <div className='write-popup-divider'></div>
                <button className='models-button' onClick={toggleModelsPopup}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>Models</span>
                    <small>Placeholder subtitle</small>
                  </div>
                  <RightArrowIcon />
                </button>
              </div>
            )}
            {isModelsPopupOpen && (
              <div className="models-popup-menu">
                <div className="models-popup-header">
                  <span>Choose Model</span>
                  <button className='models-popup-close' onClick={toggleModelsPopup}><CloseXIcon /></button>
                </div>
                <button onClick={() => handleModelSelection('Sonnet 3.5')}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>Sonnet 3.5</span>
                    <small>Placeholder subtitle</small>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={() => handleModelSelection('Haiku 3.5')}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>Haiku 3.5</span>
                    <small>Placeholder subtitle</small>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={() => handleModelSelection('o1 preview')}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>o1 preview</span>
                    <small>Placeholder subtitle</small>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={() => handleModelSelection('llama 3.2')}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>llama 3.2</span>
                    <small>Placeholder subtitle</small>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={() => handleModelSelection('Hermes 3.1')}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>Hermes 3.1</span>
                    <small>Placeholder subtitle</small>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={() => console.log('See all models')}>
                  <div className='write-popup-icon'>
                    <WritePopupIcon />
                  </div>
                  <div className='write-popup-text'>
                    <span>More models</span>
                  </div>
                  <button className="see-all-button">
                    <span>See all</span>
                  </button>
                </button>
              </div>
            )}
            {isStylePopupOpen && (
              <div className="style-popup-menu" ref={stylePopupRef}>
                <div className="style-popup-header">
                  <span>Change Your Style</span>
                  <button className='style-popup-close' onClick={toggleStylePopup}><CloseXIcon /></button>
                </div>
                <button onClick={(e) => {
                  e.preventDefault();
                  const checkbox = e.currentTarget.querySelector('.hidden-checkbox');
                  checkbox.checked = !checkbox.checked;
                }}>
                  <div className='style-popup-text'>
                    <span>Menacing</span>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={(e) => {
                  e.preventDefault();
                  const checkbox = e.currentTarget.querySelector('.hidden-checkbox');
                  checkbox.checked = !checkbox.checked;
                }}>
                  <div className='style-popup-text'>
                    <span>Fantastical</span>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={(e) => {
                  e.preventDefault();
                  const checkbox = e.currentTarget.querySelector('.hidden-checkbox');
                  checkbox.checked = !checkbox.checked;
                }}>
                  <div className='style-popup-text'>
                    <span>Spirited</span>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={(e) => {
                  e.preventDefault();
                  const checkbox = e.currentTarget.querySelector('.hidden-checkbox');
                  checkbox.checked = !checkbox.checked;
                }}>
                  <div className='style-popup-text'>
                    <span>Commanding</span>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={(e) => {
                  e.preventDefault();
                  const checkbox = e.currentTarget.querySelector('.hidden-checkbox');
                  checkbox.checked = !checkbox.checked;
                }}>
                  <div className='style-popup-text'>
                    <span>Romantic</span>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
                <button onClick={(e) => {
                  e.preventDefault();
                  const checkbox = e.currentTarget.querySelector('.hidden-checkbox');
                  checkbox.checked = !checkbox.checked;
                }}>
                  <div className='style-popup-text'>
                    <span>Sensual</span>
                  </div>
                  <label className="custom-checkbox">
                    <input type="checkbox" className="hidden-checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingToolbar;
