import React from 'react';
import { useTheme } from '../ThemeContext';
import { ThemePlace, RightArrowIcon } from './SVGLibrary';

const ThemeMenu = ({ isProfileDropdownOpen, isThemesSideMenuOpen, toggleThemesSideMenu, themesSideMenuRef }) => {
  const { updateTheme } = useTheme();

  const handleThemeChange = (themeName) => {
    console.log('handleThemeChange called with:', themeName);
    document.body.classList.remove('lumina-theme', 'umbra-theme', 'chloros-theme', 'rosequantum-theme', 'nebulous-theme', 'quasar-theme');
    console.log('Removed old theme classes');
    document.body.classList.add(`${themeName}-theme`);
    console.log('Added new theme class:', `${themeName}-theme`);
    updateTheme(themeName);
    console.log('Called updateTheme with:', themeName);
  };

  return (
    <>
      <button className='dropdown-themes' onClick={(e) => {
        e.stopPropagation();
        toggleThemesSideMenu(e);
      }}>
        Themes <RightArrowIcon/>
      </button>
      
      {isProfileDropdownOpen && isThemesSideMenuOpen && (
        <div ref={themesSideMenuRef} className="themes-side-menu">
          {['lumina', 'umbra', 'chloros', 'rosequantum', 'nebulous', 'quasar'].map((themeName) => (
            <button key={themeName} onClick={(e) => {
              e.stopPropagation();
              console.log('Theme button clicked:', themeName);
              handleThemeChange(themeName);
            }}>
              <ThemePlace /> {themeName.charAt(0).toUpperCase() + themeName.slice(1)}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default ThemeMenu;
