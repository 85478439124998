const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

export const generateOutline = async (worldId, worldData) => {
  console.log('Outline Generation Request:', { 
    worldId,
    worldDataExists: !!worldData
  });
  
  try {
    const response = await fetch(`${AI_API_URL}/storycraft/generate-outline`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        world_id: worldId,
        world_data: worldData
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Outline Generation Error:', {
        status: response.status,
        error: errorText
      });
      throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Outline Generation Success:', {
      success: data.success,
      hasOutline: !!data.outline
    });
    return data;
  } catch (error) {
    console.error('Outline Generation Failed:', {
      error: error.message,
      stack: error.stack
    });
    throw error;
  }
};