import React, { useState, useEffect } from 'react';
import './RightSidebar.css';
import { LoadingIcon, OptionIcon, SidebarBookmarksIcon, SidebarRedoIcon } from './SVGLibrary';
import RewriteCard from './RewriteCard';
import { fetchSavedRewrites } from '../services/bubbleMenuService';

const RightSidebar = ({ 
  rewriteOptions, 
  originalText, 
  operationType, 
  onInsert, 
  isLeftSidebarExtended, 
  savedRewrites = []
}) => {
  return (
    <div className={`right-sidebar ${isLeftSidebarExtended ? 'minimized' : ''}`}>
      <div className={`right-sidebar-content ${isLeftSidebarExtended ? 'hidden' : ''}`}>
      <div className="sidebar-header">
         <div className="sidebar-header-title">
          <LoadingIcon />
          <span>Rewrites</span>
        </div>
        <div className="sidebar-header-options">
          <button className= "sidebar-redo-button">
            <SidebarRedoIcon />
          </button>
          <button className= "sidebar-bookmarks-button">
            <SidebarBookmarksIcon />
          </button>
          </div>
        </div>
        <div className="rewrite-options">
          {rewriteOptions.map((rewrittenText, index) => (
            <RewriteCard
              key={`current-${index}`}
              originalText={originalText}
              rewrittenText={rewrittenText}
              onInsert={onInsert}
              operationType={operationType}
            />
          ))}
          
          {savedRewrites.map((rewrite) => (
            <RewriteCard
              key={`saved-${rewrite.id}`}
              originalText={rewrite.original_text}
              rewrittenText={rewrite.rewritten_text}
              onInsert={onInsert}
              operationType={rewrite.rewrite_type}
              isBookmarked={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;