import React, { useState, useCallback, useRef, useEffect } from 'react';
import './SageChat.css';
import { SageChatIcon, SageAskIcon, PlusIcon, SageBubble } from './SVGLibrary';
import { sendChatMessage } from '../services/chatService';
import { bubbleMenuRewrite } from '../services/bubbleMenuService';
import { 
  analyzeViewport, 
  improveText,
  analyzeCharacters,
  analyzeDialogue,
  analyzePlot,
  analyzeDescription,
  analyzeTheme,
  analyzeStory,
  analyzeStyle,
  analyzeCreative
} from '../services/sageChatService';
import SuggestionDisplay from './SuggestionDisplay';

const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const SageChat = ({ 
  selectedText, 
  projectId, 
  onReplaceText,
  isVisible,
  position,
  onClose,
  editor,
  offsetFromBottom = 120
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const [suggestion, setSuggestion] = useState(null);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState([
    {
      id: 'analyze_story',
      description: 'Story Analysis',
      subActions: [
        { id: 'improve_characters', description: 'Improve Characters' },
        { id: 'enhance_dialogue', description: 'Enhance Dialogue' },
        { id: 'strengthen_plot', description: 'Strengthen Plot' },
        { id: 'polish_description', description: 'Polish Description' },
        { id: 'theme_symbolism', description: 'Theme & Symbolism' }
      ]
    },
    {
      id: 'style_insights',
      description: 'Style Insights',
      subActions: [
        { id: 'voice_tone', description: 'Voice & Tone' },
        { id: 'prose_rhythm', description: 'Prose Rhythm' },
        { id: 'word_choice', description: 'Word Choice' },
        { id: 'sentence_variety', description: 'Sentence Variety' },
        { id: 'stylistic_devices', description: 'Stylistic Devices' }
      ]
    },
    {
      id: 'creative_directions',
      description: 'Creative Directions',
      subActions: [
        { id: 'plot_possibilities', description: 'Plot Possibilities' },
        { id: 'character_arcs', description: 'Character Arcs' },
        { id: 'thematic_depth', description: 'Thematic Depth' },
        { id: 'scene_development', description: 'Scene Development' },
        { id: 'world_building', description: 'World Building' }
      ]
    }
  ]);
  const conversationId = `chat_${projectId}_${Date.now()}`;
  const chatRef = useRef(null);
  const [isChatMenuVisible, setIsChatMenuVisible] = useState(false);
  const [isSecondState, setIsSecondState] = useState(false);
  const [loadedItems, setLoadedItems] = useState([]);
  const [currentSubActions, setCurrentSubActions] = useState([]);
  const [currentMainAction, setCurrentMainAction] = useState(null);

  const handleSubmit = async () => {
    if (!inputMessage.trim()) return;
    
    setIsLoading(true);
    try {
      const response = await sendChatMessage(
        inputMessage,
        selectedText,
        projectId,
        conversationId
      );
      setResponse(response);
      setInputMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMainActionClick = async (mainAction) => {
    setIsLoading(true);
    const selectedMainAction = actions.find(action => action.id === mainAction.id);
    if (selectedMainAction) {
      setCurrentMainAction(mainAction.id);
      setCurrentSubActions(selectedMainAction.subActions);
      selectedMainAction.subActions.forEach((_, index) => {
        setTimeout(() => {
          setLoadedItems(prev => [...prev, index]);
        }, index * 100);
      });
    }
    setIsLoading(false);
  };

  const handleSubActionClick = async (subAction) => {
    setIsLoading(true);
    try {
      const visibleText = editor?.state.doc.textBetween(0, editor?.state.doc.content.size) || '';
      let response;

      switch (currentMainAction) {
        case 'analyze_story':
          response = await analyzeStory(subAction.id, projectId, visibleText);
          break;
        case 'style_insights':
          response = await analyzeStyle(subAction.id, projectId, visibleText);
          break;
        case 'creative_directions':
          response = await analyzeCreative(subAction.id, projectId, visibleText);
          break;
      }

      setSuggestion(response);
    } catch (error) {
      console.error('Error handling sub-action:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      const timeouts = [];
      for (let i = 0; i < 3; i++) {
        timeouts.push(setTimeout(() => {
          setLoadedItems(prev => [...prev, i]);
        }, i * 500));
      }
      return () => timeouts.forEach(clearTimeout);
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <>
      <div
        ref={chatRef}
        className="sagechat sagechat-grid"
      >
        <div className="sagechat-container"></div>
        <div className={`grid-item central-circle ${isLoading ? 'loading' : ''}`}>
          <SageBubble />
          <div className="circle-icon">
            <SageAskIcon />
          </div>
        </div>
        {currentSubActions.length === 0 ? (
          actions.map((action, index) => (
            <button
              key={action.id}
              className={`grid-item radial-menu-item position-${index + 1} ${loadedItems.includes(index) ? 'visible' : 'hidden'}`}
              onClick={() => handleMainActionClick(action)}
            >
              <span>{action.description}</span>
            </button>
          ))
        ) : (
          currentSubActions.map((subAction, index) => (
            <button
              key={subAction.id}
              className={`grid-item radial-menu-item position-${index + 1} ${loadedItems.includes(index) ? 'visible' : 'hidden'}`}
              onClick={() => handleSubActionClick(subAction)}
            >
              <span>{subAction.description}</span>
            </button>
          ))
        )}
        <button className="grid-item ask-me-button" onClick={() => setIsSecondState(true)}>
          <span>Ask Me</span>
        </button>
      </div>

      {suggestion && (
        <div className="sagechat-suggestions-box">
          <div className="sagechat-suggestions-content">
            {suggestion.map((item) => (
              <div key={item.id} className="sagechat-suggestion-item">
                <h3>{item.description}</h3>
                {item.improved_text && (
                  <div className="sagechat-improvement">
                    <p>{item.improved_text}</p>
                    <p className="sagechat-explanation">{item.explanation}</p>
                  </div>
                )}
                {item.creative_direction && (
                  <div className="sagechat-creative">
                    <p><strong>Direction:</strong> {item.creative_direction}</p>
                    <p><strong>Implementation:</strong> {item.implementation}</p>
                    <p><strong>Impact:</strong> {item.impact}</p>
                    <span className={`sagechat-complexity ${item.complexity}`}>
                      {item.complexity}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SageChat;
